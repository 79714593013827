import React, { Component } from 'react'

export class Header extends Component {

  render() {
    const loginObject = JSON.parse(localStorage.getItem('login'));
    let token = loginObject.jwt;
    let shopId = loginObject.shop_id;
    let userId = loginObject.user_id;
    let shopName = loginObject.shopname;
    return (
      <nav className="navbar navbar-expand-lg navbar-light bg-light pt-3 pb-3 pl-5 pr-4" style={{ backgroundColor: '#ffffff' }}>
        <a className="navbar-brand animate__animated animate__fadeIn" href="/home"><img src="assets/images/logo/logo.png" className="main-logo" /></a>
        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>

        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav mr-auto">
            <li className="nav-item">
              <form className="form-inline my-2 my-lg-0 animate__animated animate__fadeIn" style={{ position: 'relative' }}>
                <img src="assets/images/icons/search.png" className="search-icon" />
                <input className="form-control mr-sm-2 main-search" type="search" placeholder="Search for any order..." aria-label="Search" />
              </form>
            </li>
            <li className="nav-item">
              <a className="nav-link recent-orders animate__animated animate__fadeIn" href="#">Recent Orders</a>
            </li>
          </ul>

          <ul className="navbar-nav ml-auto">

            <li className="nav-item ">
              <a className="nav-link animate__animated animate__backInDown" href=""><img src="assets/images/icons/refresh.png" className="navbar-icons" alt="navbar-icons" /></a>
            </li>
            <li className="nav-item">
              <a className="nav-link animate__animated animate__backInDown" href=""><img src="assets/images/icons/pages.png" className="navbar-icons" alt="navbar-icons" /></a>
            </li>
            <li className="nav-item">
              <a className="nav-link animate__animated animate__backInDown" href=""><img src="assets/images/icons/basket.png" className="navbar-icons" alt="navbar-icons" /></a>
            </li>
            <li className="nav-item">
              <a className="nav-link animate__animated animate__backInDown" href=""><img src="assets/images/icons/notepad.png" className="navbar-icons" alt="navbar-icons" /></a>
            </li>
            <li className="nav-item">
              <a className="nav-link animate__animated animate__backInDown" href=""><img src="assets/images/icons/mail.png" className="navbar-icons last" alt="navbar-icons" /></a>
            </li>

            <li className="nav-item dropdown user-dropdown animate__animated animate__fadeIn">
              <a className="nav-link dropdown-toggle user-name" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <img src="assets/images/icons/user.png" className="navbar-user-icon" alt="navbar-icons" />{ shopName}
              </a>
              <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                <a className="dropdown-item" href="#">Logout</a>
              </div>
            </li>

          </ul>
        </div>
      </nav>
    )
  }
}

export default Header
