export const initialState = {
    itemsInBasket:[]
};
const itemReducer = (state = initialState, action) => {
    switch(action.type) {
        case 'ADD_ITEM':
            return {
                ...state, 
                itemsInBasket:[...state.itemsInBasket, action.payload]
            }
        default:
            return state;
    }
};

export default itemReducer;