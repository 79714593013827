export const addItem = ({item}) => {
    return {
        type: "ADD_ITEM",
        payload: item
    }
}

// export const searchItem = (itemId) => {
//     return {
//         type: "SEARCH_ITEM",
//         payload: itemId,
//     }
// }

// export const setUser = (user) => {
//     return {
//         type: "SET_USER",
//         payload: user,
//     }
// }

// export const setShop = (shop) => {
//     return {
//         type: "SET_SHOP",
//         payload: shop,
//     }
// }
