import React, { useEffect, useState } from "react";
import { Route, Switch } from 'react-router-dom';
import Loginscreen from "./screen/Loginscreen";
import Dashboard from "./screen/Dashboard";
import Dispatch from "./screen/Dispatch";
import Cash from "./screen/Cashandcarry";
import Neworder from "./screen/Neworder";

export default function App() {
  return (
    <main>
      <Switch>
        <Route exact path="/" component={Loginscreen} />
        <Route exact path="/login" component={Loginscreen} />
        <Route exact path="/home" component={Dashboard} />
        <Route exact path="/dispatch" component={Dispatch} />
        <Route exact path="/cash" component={Cash} />
        <Route exact path="/neworder" component={Neworder} />
      </Switch>
    </main> 
  );
}
