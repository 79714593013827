import React, { useEffect, useState } from 'react';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Rightnav from './Responsiverightnav';
import Header from "./Header";
import { FaCalendar } from 'react-icons/fa';

export default function Dispatch() {
  const loginObject = JSON.parse(localStorage.getItem('login'));
  let token = loginObject.jwt;
  let shopId = loginObject.shop_id;
  let userId = loginObject.user_id;
  let shopName = loginObject.shopname;

  // variables for form
  const [driver, setDriver] = useState();
  const [newList, setNewList] = useState([]);

  const [startDate, setStartDate] = useState(new Date());
  var month = startDate.getUTCMonth() + 1;
  var day = startDate.getUTCDate();
  var year = startDate.getUTCFullYear();

  const newStartDate = `${year}-${month}-${day}`;

  console.log("The new Date is:", newStartDate);
  const [pendDelivaries, setPendDeliveries] = useState([]);

  console.log("All Pendings are:", pendDelivaries);

  useEffect(() => {
    getPendingOrdersforDelivery();
  }, [newStartDate]);

  const getPendingOrdersforDelivery = () => {
    const url = "https://apifloragen.gamingpandastudios.com/api/delivery_date_search";
    const requestBody = {
      shop_id: shopId,
      dlvdate: newStartDate,
      delivery_status: "pending_confirmation",
      jwt: token
    }

    try {
      fetch(url, {
        method: "POST",
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(requestBody),
      })
        .then((res) => res.json())
        .then((data) => {
          console.log("Pending Orders Info: ", data.records);
          setPendDeliveries(data.records);
        })
        .catch((e) => console.log(e));
    }
    catch (e) {
      console.log(e);
    }
  }

  const handleAssignDriver = (e) => {
    e.preventDefault();
    const url = " https://apifloragen.gamingpandastudios.com/api/assign_driver";
    const requestBody = {
      jwt: token,
      dispatches : newList.map((order) => ({
        driverid: driver,
        order_id: order.order_id,
        shopid: shopId
      })),
    }
    console.log("request Body is:", requestBody);
    try {
      fetch(url, {
        method: "POST",
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(requestBody),
      })
        .then((res) => res.json())
        .then((data) => {
          console.log("Assign Driver Info: ", data);
          setNewList([]);
          setDriver();
        })
        .catch((e) => console.log(e));
    }
    catch (e) {
      console.log(e);
    }
  }
  // const [newPendDeliveries, setNewPendDeliveries] = useState([]);

  const handleRow = (e, delivery, i) => {
    e.preventDefault();
    console.log("Delivery row is:", delivery);
    console.log("Its Index is:", i);
    let list = [...newList];
    list.push(delivery);
    setNewList(list);
    setPendDeliveries(pendDelivaries.filter(pd => pd.order_id !== delivery.order_id));
  }

  console.log("New list is:", newList)

  return (
    <>
      <Header />
      <section>
        <div className="row">
          <div className="col-md-11 lso">

            <div className="left-section">
              <div className="row">
                <div className="col-md-5">
                  <div className="row">
                    <div className="col-md-4">
                      <h1 className="welcome-h1 animate__animated animate__fadeIn"><img src="assets/images/icons/dispatch_blue.png" className="main-page-logo" /> Dispatch</h1>
                    </div>
                    <div className="col-md-8">
                      <div className="btn-group">
                        <p className="page_desc_p">Show orders for all shops</p>
                        <div className="custom-control custom-switch">
                          <input type="checkbox" className="custom-control-input" id="customSwitches" />
                          <label className="custom-control-label" for="customSwitches"></label>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
                <div className="col-md-7">
                  <div className="row">
                    <div className="col-md-2 select-divs animate__animated animate__fadeIn">
                      <p className="page_desc_p" style={{ float: "right" }}>Delivery Date</p>
                    </div>
                    <div className="col-md-3 select-divs animate__animated animate__fadeIn" style={{ zIndex: "100", display: "flex", alignItems: "center" }}>
                      <FaCalendar /> &nbsp;
                      {/* <select className="form-control top-select">
                        <option>7/13/2021</option>
                      </select> */}
                      <DatePicker selected={startDate} className="form-control zz" onChange={(date) => setStartDate(date)} />
                    </div>
                    <div className="col-md-2 select-divs animate__animated animate__fadeIn">
                      <p className="page_desc_p" style={{ float: "right" }}>Select Shop</p>
                    </div>
                    <div className="col-md-5 select-divs animate__animated animate__fadeIn">
                      <img src="assets/images/icons/online.png" className="top-select-img" />
                      <select className="form-control top-select">
                        <option value={shopId} key={shopId}>{shopName}</option>

                      </select>
                    </div>
                  </div>
                </div>
              </div>
              <br />
              <div className="row">
                <div className="col-md-7">

                  <div className="other-page-inner-left-section">
                    <div className="row">
                      <div className="col-md-4">
                        <h2>Pending Deliveries</h2>
                      </div>
                      <div className="col-md-8">

                        <div className="row">
                          <div className="col-md-4 mini-icon-div">
                            <h3><img src="assets/images/icons/info-icon.png" className="mini-icon" />Order Details</h3>
                          </div>
                          <div className="col-md-3 mini-icon-div">
                            <h3><img src="assets/images/icons/clock-icon.png" className="mini-icon" />Timed Orders</h3>
                          </div>
                          <div className="col-md-5 mini-icon-div">
                            <h3><img src="assets/images/icons/check-icon.png" className="mini-icon" />Design Completed</h3>
                          </div>
                        </div>

                      </div>
                    </div>

                    {/* <div className="decor-line"></div> */}

                    <div className="tab-content-div pt-0">
                      <div className="table-responsive-sm">
                        <table className="table">
                          <thead>
                            <tr>
                              <th scope="col">Sr.No.</th>
                              <th scope="col">Invoice Number</th>
                              <th scope="col">Name</th>
                              <th scope="col">Address</th>
                            </tr>

                          </thead>
                          <tbody>
                            {pendDelivaries ?
                              pendDelivaries.map((delivery, i) => {
                                return (
                                  <tr onClick={(e) => handleRow(e, delivery, i)}>
                                    <td><p className="grey">{i + 1}</p></td>
                                    <td><p className="blue">{delivery.invoice}</p></td>
                                    <td><p>{delivery.firstname} {delivery.lastname}</p> </td>
                                    <td><p className="grey">{delivery.address1}, {delivery.city} {delivery.state}, {delivery.zipcode}</p> </td>
                                  </tr>
                                )
                              })
                              :
                              <p>No deliveries for {newStartDate}</p>
                            }


                          </tbody>
                        </table>
                      </div>
                    </div>

                  </div>

                  <br />
                  <div className="other-page-inner-left-section">
                    <div className="row">
                      <div className="col-md-5">
                        <h2>Summary</h2>
                      </div>
                      <div className="col-md-7">

                        {/*<div className="row">
                <div className="col-md-4">
                  <h3>Order Details</h3>
                </div>
                 <div className="col-md-4">
                   <h3>Timed Orders</h3>
                 </div>
                  <div className="col-md-4">
                    <h3>Design Completed</h3>
                  </div>
                    </div> --> */}

                      </div>
                    </div>

                    <div className="decor-line"></div>

                    <div className="tab-content-div pt-0">
                      <div className="table-responsive-sm">
                        <table className="table">
                          <thead>
                            <tr>
                              <th scope="col">Sr.No.</th>
                              <th scope="col">Summarized By</th>
                              <th scope="col">Count</th>
                              <th scope="col">Address</th>
                            </tr>
                          </thead>
                          <tbody>



                          </tbody>
                        </table>
                      </div>
                    </div>

                  </div>

                </div>
                <div className="col-md-5 pl-0 ">
                  <form onSubmit={handleAssignDriver}>
                    <div className="other-page-inner-right-section">
                      <h2>Trip</h2>
                      {/* <div className="decor-line"></div> */}

                      <div className="row mt-4">
                        <div className="col-md-6">
                          <div className="btn-group">
                            <p style={{ width: "70%", marginTop: 1 }}>Select Driver</p>
                            <select className="form-control other-select" value={driver} onChange={(e) => setDriver(e.target.value)}>
                              <option>--Select a Driver--</option>
                              <option value="1">Driver 1</option>
                              <option value="2">Driver 2</option>
                              <option value="3">Driver 3</option>
                              <option value="4">Driver 4</option>
                              <option value="5">Driver 5</option>
                            </select>
                          </div>

                        </div>
                        <div className="col-md-6">
                          <p style={{ marginTop: 1 }}>Trip Number:</p>
                        </div>
                      </div>
                      <hr />

                      <p style={{ marginTop: 1 }}>Routed Number:</p>
                      <hr />

                      <p style={{ marginTop: 1 }}>Created:</p>
                      <hr />

                      <p style={{ marginTop: 1 }}>By:</p>
                      <hr />

                      <div className="tab-content-div pt-0">
                        <div className="table-responsive-sm">
                          <table className="table">
                            <thead>
                              <tr>
                                <th scope="col">Invoice Number</th>
                                <th scope="col">Name</th>
                                <th scope="col">City</th>
                                <th scope="col">State</th>
                                <th scope="col">ZipCode</th>
                              </tr>

                            </thead>
                            <tbody>
                              {newList ?
                                newList.map((delivery, i) => {
                                  return (
                                    <tr key={i}>
                                      <td><p className="grey">{delivery.invoice}</p></td>
                                      <td><p className="blue">{delivery.firstname} {delivery.lastname}</p></td>
                                      <td><p>{delivery.city}</p> </td>
                                      <td><p className="grey">{delivery.state}</p> </td>
                                      <td><p className="grey">{delivery.zipcode}</p> </td>
                                    </tr>
                                  )
                                })
                                :
                                <p>No deliveries.</p>
                              }


                            </tbody>
                          </table>
                        </div>
                      </div>

                      <p style={{ marginTop: 1, color: "#0170f7" }}>Total Deliveries:</p>
                      <hr />


                      <div className="row">
                        <div className="col-md-3">
                          <p style={{ marginTop: 1 }}>Scan Orders</p>
                        </div>
                        <div className="col-md-9">
                          <input type="text" className="form-control other-select" name="" placeholder="Enter Invoice Number" />
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-md-3">
                          <button type="submit" className="btn btn-lg btn-primary">Save</button>
                        </div>
                      </div>

                      <div className="note-div mt-5">
                        <h3>Note</h3>
                        <p>Remote Print : Use this option to print trip sheet (Driver Manifest)
                          to your store printer from anywhere in the world.</p>
                      </div>


                    </div>
                  </form>

                </div>
              </div>

            </div>
          </div>


          <Rightnav />
        </div>
      </section></>
  )
}