import React, { useEffect, useState } from 'react';
import Rightnav from './Responsiverightnav';
import Modal from 'react-bootstrap/Modal'
import Form from 'react-bootstrap/Form'
import InputGroup from 'react-bootstrap/InputGroup'
import FormControl from 'react-bootstrap/FormControl'
import Button from 'react-bootstrap/Button'
import Header from "./Header";
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { addItem } from '../actions';
import { useHistory } from 'react-router';

export default function Cashandcarry() {
  const history = useHistory();
  const handleOrder = (e) => {
    e.preventDefault();
    // console.log("Order submissin is gere.")

    const loginObject = JSON.parse(localStorage.getItem('login'));
    let token = loginObject.jwt;
    let shopId = loginObject.shop_id;
    let userId = loginObject.user_id;
    let shopName = loginObject.shopname;
    // console.log("User key:", token);

    const finalUrl = "https://apifloragen.gamingpandastudios.com/api/create_order";

    const requestBody = {
      invoice: "1160062123",
      user_id: userId,
      shop_id: shopId,
      firstname: m_fname,
      lastname: m_lname,
      address1: m_address1,
      address2: m_address2,
      city: m_city,
      state: m_state,
      zipcode: m_zip,
      ordertype: "Web Order",
      occasion: "anniversary",
      source_code: "22",
      dlv_type: "Delivery",
      mop: "Paid Outside Hana",
      coupon_code: "112021",
      tax_id: "VT1",
      discount_percentage: getTotalDiscountPercent(),
      discount_amount: getTotalDiscount(),
      sub_total: getSubTotal(),
      tax_amount: tax,
      grand_total: getGrandTotal(),
      customerid: localStorage.getItem('CustomerId'),
      dlvdate: "2021-10-22",
      dlvtime: "10:20:28",
      delivery_status: "pending",
      jwt: token,
      items: itemsInBasket.itemsInBasket.map((item) => ({
        itemid: item.itemid,
        itemqty: item.itemqty,
        price: item.price,
        discount_amount: item.discount_amount,
        discount_percentage: item.discount_percentage
    })),
    }

    try {
      fetch(finalUrl, {
        method: "POST",
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(requestBody),
      })
        .then((res) => res.json())
        .then((data) => {
          console.log("Item Info: ", data);
          toast.success("Your order has been created.", {
            position: "top-center"
          })
          history.push('/home');
        })
        .catch((e) => {
          toast.error(e, {
            position: "top-center"
          })
        });
    }
    catch (e) {
      toast.error(e, {
        position: "top-center"
      })
    }
    // console.log("The final order body is:", requestBody);

  }

  const [shopItems, setShopItems] = useState([]);
  const itemsInBasket = useSelector((state) => state.itemReducer);
  console.log("Items in Basket: ", itemsInBasket);

  const [tax, setTax] = useState(0.00);

  const [modalShow, setModalShow] = useState(false);

  const [man_itemcode, setMan_itemcode] = useState();
  const [man_itemname, setMan_itemname] = useState();
  const [man_discription, setMan_discription] = useState();
  const [man_qty, setMan_qty] = useState();
  const [man_price, setMan_price] = useState();
  const [man_discount, setMan_discount] = useState();
  const [man_discountPercent, setMan_discountPercent] = useState();
  const [actualCustomer, setActualCustomer] = useState('');

  const [searchEmail, setSearchEmail] = useState('');
  const [m_companyname, setM_companyname] = useState('');
  const [m_address2, setM_address2] = useState('');
  const [m_country, setM_country] = useState('');
  const [m_fname, setM_fname] = useState('');
  const [m_zip, setM_zip] = useState('');
  const [m_address1, setM_address1] = useState('');
  const [m_phone, setM_phone] = useState('');
  const [m_credit, setM_credit] = useState('');
  const [m_lname, setM_lname] = useState('');
  const [m_city, setM_city] = useState('');
  const [m_altno, setM_altno] = useState('');
  const [m_state, setM_state] = useState('');
  const [m_email, setM_email] = useState('');
  const [isCreditApproved, setIsCreditApproved] = useState(false);
  const [m_customerType, setM_customerType] = useState('');

  const handleItemCode = (s_item) => {
    const selectedItem = shopItems.find(item => item.itemid == s_item);
    // console.log("SelectedItem is:",selectedItem);
    selectedItem ? setMan_itemcode(selectedItem.itemid) : setMan_itemcode('');
    selectedItem ? setMan_itemname(selectedItem.itemname) : setMan_itemname('');
    selectedItem ? setMan_discription(selectedItem.itemdesc) : setMan_discription('');
    selectedItem ? setMan_qty(1) : setMan_qty('');
    selectedItem ? setMan_price(selectedItem.price) : setMan_price('');
    selectedItem ? setMan_discount(0.00) : setMan_discount();
    selectedItem ? setMan_discountPercent(0.00) : setMan_discountPercent();

  }

  const handleModalShow = (e) => {
    e.preventDefault();
    setModalShow(!modalShow);
  }

  const handleSearchEmail = () => {
    const loginObject = JSON.parse(localStorage.getItem('login'));
    let token = loginObject.jwt;
    let shopId = loginObject.shop_id;
    const url = "https://apifloragen.gamingpandastudios.com/api/customer_search";
    const requestBody = {
      email: searchEmail,
      shop_id: shopId,
      jwt: token
    }
    try {
      fetch(url, {
        method: "POST",
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(requestBody),
      })
        .then((res) => res.json())
        .then((data) => {
          console.log("Searched Customer is: ", data.records);
          setM_companyname(data.records[0].companyname);
          setM_address2(data.records[0].address2);
          setM_country(data.records[0].country);
          setM_fname(data.records[0].firstname);
          setM_zip(data.records[0].zip);
          setM_address1(data.records[0].address1);
          setM_phone(data.records[0].phone1);
          setM_credit(data.records[0].store_credit);
          setM_lname(data.records[0].lastname);
          setM_city(data.records[0].city);
          setM_altno(data.records[0].phone2);
          setM_state(data.records[0].state);
          setM_customerType(data.records[0].customer_type);
          setIsCreditApproved(data.records[0].credit_approved == "1" ? true : false);
          setM_email(searchEmail);
          localStorage.setItem('postRequestForNewCustomer', 'false');
          localStorage.setItem('CustomerId', data.records[0].cust_id);
          setSearchEmail('');
          toast.success("The customer details are fetched.", {
            position: "top-center"
          })
          // setActualCustomer(dalocalStorage.getItem('CustomerId')ta.records[0].cust_id);
        })
        .catch((e) => {
          setM_companyname('');
          setM_address2('');
          setM_country('');
          setM_fname('');
          setM_zip('');
          setM_address1('');
          setM_phone('');
          setM_credit('');
          setM_lname('');
          setM_city('');
          setM_altno('');
          setM_state('');
          setM_customerType('');
          setIsCreditApproved(false);
          setM_email('');
          localStorage.setItem('postRequestForNewCustomer', 'true');
          console.log(e)
          localStorage.setItem('CustomerId', null);
          toast.warning("This user does not exists.", {
            position: "top-center"
          })
        });
    }
    catch (e) {
      console.log(e);
    }
    console.log("isApproved: ", isCreditApproved);
  }

  const handleItemSubmit = (e) => {
    e.preventDefault();
    const item = {
      itemid: man_itemcode,
      itemname: man_itemname,
      itemdesc: man_discription,
      itemqty: man_qty,
      price: man_price,
      discount_amount: parseFloat(man_discount),
      discount_percentage: parseFloat(man_discountPercent),
    }

    console.log("New Item is:", item);
    dispatch(addItem({ item }));
  }

  function getSubTotal() {
    const sub = itemsInBasket.itemsInBasket?.reduce((sub, { price, itemqty }) => sub + parseFloat(price) * parseFloat(itemqty), 0);
    return sub;
  }

  function getTotalDiscount() {
    const sub = itemsInBasket.itemsInBasket?.reduce((sub, { discount_percentage, price, itemqty }) => sub + (parseFloat(discount_percentage / 100) * parseFloat(price) * parseFloat(itemqty)), 0);
    return sub;
  }

  function getTotalDiscountPercent() {
    const sub = itemsInBasket.itemsInBasket?.reduce((sub, { discount_percentage }) => sub + parseFloat(discount_percentage), 0);
    return sub;
  }

  function getGrandTotal() {
    return getSubTotal() + parseFloat(tax) - getTotalDiscount();
  }

  const handleModalData = (e) => {
    e.preventDefault();
    if (localStorage.getItem('postRequestForNewCustomer') && localStorage.getItem('CustomerId')) {
      const loginObject = JSON.parse(localStorage.getItem('login'));
      let token = loginObject.jwt;
      let shopId = loginObject.shop_id;
      const url = "https://apifloragen.gamingpandastudios.com/api/create_customer";
      const requestBody = {
        firstname: m_fname,
        lastname: m_lname,
        companyname: m_companyname,
        email: m_email,
        address1: m_address1,
        address2: m_address2,
        city: m_city,
        state: m_state,
        country: m_country,
        zip: m_zip,
        phone1: m_phone,
        phone2: m_altno,
        shop_id: shopId,
        customer_type: m_customerType,
        store_credit: m_credit,
        credit_approved: isCreditApproved ? "1" : "0",
        jwt: token
      }
      // console.log("The data to be sent for new Customer is:", requestBody);

      try {
        fetch(url, {
          method: "POST",
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(requestBody),
        })
          .then((res) => res.json())
          .then((data) => {
            console.log("Customer Info: ", data);
            toast.success(data, {
              position: "top-center"
            })
            setTimeout(() => {
              toast.success("Search for customer from its email and click Add Customer", {
                position: "top-center"
              })
            }, 6000);

          })
          .catch((e) => {
            toast.error(e, {
              position: "top-center"
            })
          });
      }
      catch (e) {
        toast.error(e, {
          position: "top-center"
        });
      }
    }
    else {
      const customer_id = localStorage.getItem('CustomerId');
      setActualCustomer(customer_id);
      console.log("customerId is:", customer_id);
      toast.success("Customer Id is set", {
        position: "top-center"
      })
    }
  }

  const dispatch = useDispatch();
  useEffect(() => {
    localStorage.setItem('CustomerId', '');
    getItemList();

  }, []);

  const getItemList = () => {
    const loginObject = JSON.parse(localStorage.getItem('login'));
    let token = loginObject.jwt;
    const url = "https://apifloragen.gamingpandastudios.com/api/item_list";
    const requestBody = {
      jwt: token,
    }

    try {
      fetch(url, {
        method: "POST",
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(requestBody),
      })
        .then((res) => res.json())
        .then((data) => {
          console.log("Item Info: ", data.records);
          setShopItems(data.records);
        })
        .catch((e) => console.log(e));
    }
    catch (e) {
      console.log(e);
    }
  }
  return (
    <>
      <Header />
      <Modal
        show={modalShow}
        onHide={() => setModalShow(false)}
        size="xl" aria-labelledby="example-modal-sizes-title-lg">
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">
            ADD CUSTOMER
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>

          <div className="row">
            <div className="col-md-6">
              <Form.Label>Search Customer</Form.Label>
              <InputGroup className="mb-3">

                <Form.Control type="email" placeholder="Enter Customer Email" value={searchEmail} onChange={(e) => setSearchEmail(e.target.value)} />
                <Button variant="primary" type="submit" onClick={handleSearchEmail}>
                  Search
                </Button>
              </InputGroup>
            </div>
          </div>

          <Form >
            <div className="row">
              <div className="col-md-3">
                <Form.Group className="mb-3">
                  <Form.Label>Customer Company</Form.Label>
                  <Form.Control type="text" placeholder="Enter Company Name" value={m_companyname} onChange={(e) => setM_companyname(e.target.value)} />
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>Address2</Form.Label>
                  <Form.Control type="text" placeholder="Enter Address2" value={m_address2} onChange={(e) => setM_address2(e.target.value)} />
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>Country</Form.Label>
                  <Form.Control type="text" placeholder="Enter Country" value={m_country} onChange={(e) => setM_country(e.target.value)} />
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>Customer Type</Form.Label>

                  <Form.Select aria-label="Default select example" className="form-control" value={m_customerType} onChange={(e) => setM_customerType(e.target.value)}>
                    <option>--Select--</option>
                    <option value="regular">Regular</option>
                  </Form.Select>
                </Form.Group>


              </div>
              <div className="col-md-3">
                <Form.Group className="mb-3">
                  <Form.Label>First Name</Form.Label>
                  <Form.Control type="text" placeholder="Enter First Name" value={m_fname} onChange={(e) => setM_fname(e.target.value)} />
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>Zip / Postal Code</Form.Label>
                  <Form.Control type="text" placeholder="Enter Zip / Postal Code" value={m_zip} onChange={(e) => setM_zip(e.target.value)} />
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>Phone</Form.Label>
                  <Form.Control type="text" placeholder="Enter Phone Number" value={m_phone} onChange={(e) => setM_phone(e.target.value)} />
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>Store Credit</Form.Label>
                  <Form.Control type="text" placeholder="Enter Store Credit" value={m_credit} onChange={(e) => setM_credit(e.target.value)} />
                </Form.Group>
              </div>
              <div className="col-md-3">
                <Form.Group className="mb-3">
                  <Form.Label>Last Name</Form.Label>
                  <Form.Control type="text" placeholder="Enter Last Name" value={m_lname} onChange={(e) => setM_lname(e.target.value)} />
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>City</Form.Label>
                  <Form.Control type="text" placeholder="Enter City" value={m_city} onChange={(e) => setM_city(e.target.value)} />
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>Alternate Phone</Form.Label>
                  <Form.Control type="text" placeholder="Enter Alternate Phone Number" value={m_altno} onChange={(e) => setM_altno(e.target.value)} />
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>Credit Approved</Form.Label>
                  <Form.Check aria-label="option 1" type="checkbox" style={{ marginLeft: "25px" }} checked={isCreditApproved} onChange={(e) => setIsCreditApproved(e.target.checked)} />
                </Form.Group>
              </div>
              <div className="col-md-3">
                <Form.Group className="mb-3">
                  <Form.Label>Address1</Form.Label>
                  <Form.Control type="text" placeholder="Enter Address1" value={m_address1} onChange={(e) => setM_address1(e.target.value)} />
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>State</Form.Label>
                  <Form.Control type="text" placeholder="Enter State" value={m_state} onChange={(e) => setM_state(e.target.value)} />
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>Email<span style={{ color: "red" }}><b>*</b></span></Form.Label>
                  <Form.Control type="email" placeholder="Enter Email" value={m_email} onChange={(e) => setM_email(e.target.value)} required />
                </Form.Group>
              </div>
            </div>
            <div className="row">
              <div className="col-md-2 offset-10">
                <Button variant="info" type="submit" onClick={handleModalData} disabled={!m_email}>
                  Add Customer
                </Button>
              </div>
            </div>

          </Form>
        </Modal.Body>
      </Modal>

      <section>

        <div className="row">

          <div className="col-md-11 ">


            <div className="left-section">
              <div className="row">
                <div className="col-md-5">
                  <div className="row">
                    <div className="col-md-4">
                      <h1 className="welcome-h1 animate__animated animate__fadeIn"><img src="assets/images/icons/dispatch_blue.png" className="main-page-logo" />Cash & Carry</h1>
                    </div>
                    <div className="col-md-8 select-divs">
                      <img src="assets/images/icons/online.png" className="top-select-img" />
                      <select className="form-control top-select">
                        <option>Vasco Florist & Planetarium</option>
                        <option>Vasco Florist</option>
                        <option>Vasco Florist</option>
                        <option>Vasco Florist</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div className="col-md-7">
                  <div className="row">
                    <div className="col-md-4 select-divs animate__animated animate__fadeIn">
                      <select className="form-control other-select">
                        <option>Select Cash Registry</option>
                      </select>
                    </div>
                    <div className="col-md-4 select-divs animate__animated animate__fadeIn">
                      <select className="form-control other-select">
                        <option>Select User</option>
                      </select>
                    </div>
                    <div className="col-md-4 select-divs animate__animated animate__fadeIn">
                      <button className="setting-btns"><img src="assets/images/icons/pages-icon.png" /></button>
                      <button className="setting-btns"><img src="assets/images/icons/settings-icon.png" /></button>
                    </div>
                  </div>
                </div>
              </div>
              <br />
              <div className="row">
                <div className="col-md-5">
                  <div className="other-page-inner-left-section">

                    <div className="row">
                      <div className="col-md-6 coupon-outer">
                        <div className="coupon-div-main">
                          <p>Gift<br />Card</p>
                        </div>
                      </div>

                      {
                        shopItems.map((item) => {
                          item.itemqty = 1;
                          item.discount_amount = 0;
                          item.discount_percentage = 0;
                          return (
                            <div className="col-md-6 coupon-outer" >
                              <div className="coupon-div" onClick={() => dispatch(addItem({ item }))}>
                                <span>{item.itemid}</span>
                                <p>{item.itemname}</p>
                                <span>{item.itemdesc}</span>
                                <h5>${item.price}</h5>
                              </div>
                            </div>
                          )
                        })
                      }
                    </div>

                  </div>

                </div>



                <div className="col-md-7 pl-0 other-page-inner-right-section-outer">

                  <div className="other-page-inner-right-section">
                    <form>
                      <div className="row">
                        <button className="action-button-1" type="submit" onClick={handleItemSubmit}>
                          <img src="assets/images/icons/plus.png" />
                        </button>
                        <button className="action-button-2">
                          <img src="assets/images/icons/refresh-red.png" />
                        </button>
                        <div className="col-md-2">
                          <p>Item Code</p>
                          <input type="text" className="form-control other-select" name="" value={man_itemcode} onChange={(e) => handleItemCode(e.target.value)} placeholder="Item Code" />
                        </div>
                        <div className="col-md-3">
                          <p>Description</p>
                          <input type="text" className="form-control other-select" name="" value={man_discription} placeholder="Description" />
                        </div>
                        <div className="col-md-1">
                          <p>Qty</p>
                          <input type="text" className="form-control other-select" name="" value={man_qty} onChange={(e) => setMan_qty(e.target.value)} placeholder="1" />
                        </div>
                        <div className="col-md-2">
                          <p>Price</p>
                          <input type="text" className="form-control other-select" name="" value={man_price}
                            placeholder="Price" />
                        </div>
                        <div className="col-md-2">
                          <p>Discount</p>
                          <input type="text" className="form-control other-select" name="" value={(man_discountPercent > 0) ? (((man_discountPercent) / 100) * (man_price * man_qty)) : man_discount} placeholder="Discount" />
                        </div>
                        <div className="col-md-2">
                          <p>Discount%</p>
                          <input type="text" className="form-control other-select" name="" value={man_discountPercent} onChange={(e) => setMan_discountPercent(e.target.value)} placeholder="Discount%" />
                        </div>
                      </div>
                    </form>



                    <div className="item-details mt-4">
                      <div className="tab-content-div pt-0">
                        <div className="table-responsive-sm">
                          <table className="table">
                            <thead>
                              <tr>
                                <th scope="col" className="blue">Item Code</th>
                                <th scope="col" className="blue">Description</th>
                                <th scope="col" className="blue">Qty</th>
                                <th scope="col" className="blue">Price</th>
                                <th scope="col" className="blue">Discount</th>
                                <th scope="col" className="blue">Discount%</th>
                              </tr>
                            </thead>
                            <tbody>
                              {
                                itemsInBasket.itemsInBasket.map((item) => {
                                  let quantity = 1;
                                  item.discount_amount = (item.discount_percentage / 100) * (item.price * item.itemqty) ? (item.discount_percentage / 100) * (item.price * item.itemqty) : 0;
                                  return (
                                    <tr>
                                      <td>{item.itemid}</td>
                                      <td>{item.itemdesc}</td>
                                      <td>{item.itemqty ? item.itemqty : quantity}</td>
                                      <td>{item.price}</td>
                                      <td>{item.discount_amount}</td>
                                      <td>{item.discount_percentage ? item.discount_percentage : 0}</td>
                                    </tr>
                                  )
                                })
                              }

                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>


                    <form>
                      <div className="row mt-5">
                        <div className="col-md-7 item-details-outer">

                          <div className="row">
                            <div className="col-md-6">
                              <p>Coupon <span>Alt+C</span></p>
                              <input type="text" className="form-control other-select" name="" placeholder="Enter Coupon Code" />
                            </div>
                            <div className="col-md-6">
                              <p>Discount%</p>
                              <input type="text" className="form-control other-select" name="" value={getTotalDiscountPercent()} disabled />
                            </div>
                          </div>
                          <br />
                          <div className="row">
                            <div className="col-md-6">
                              <p>Discount($)</p>
                              <input type="text" className="form-control other-select" name="" value={getTotalDiscount()} disabled />
                            </div>
                            <div className="col-md-6">
                              <p>Select Customer</p>
                              
                              <InputGroup className="mb-3">
                                <FormControl type="text" placeholder="Select Customer" className="other-select" value={localStorage.getItem('CustomerId')} />
                                <Button className="btn btn-primary btn-sm" onClick={handleModalShow} style={{ marginTop: "-10px" }}>
                                  Search
                                </Button>
                              </InputGroup>
                            </div>
                          </div>
                          <br />
                          <div className="row">
                            <div className="col-md-6">
                              <p>Tax Type</p>
                              <select className="form-control other-select" onChange={(e) => setTax(e.target.value)}>
                                <option>----</option>
                                <option value="8.50" key="ST">Standard Tax</option>
                                <option value="0" key="TE">Tax Exemption</option>
                              </select>
                            </div>
                            <div className="col-md-6">
                              <p>Occasion</p>
                              <select className="form-control other-select">
                                <option>Other</option>
                                <option value="Anniversary">Anniversary</option>
                                <option value="Birthday">Birthday</option>
                                <option value="Boss Day">Boss Day</option>
                                <option value="Business Gifts">Business Gifts</option>
                                <option value="Christmas">Christmas</option>
                                <option value="Congratulations">Congratulations</option>
                                <option value="Event">Event</option>
                                <option value="Fund Raiser">Fund Raiser</option>
                                <option value="Get Well">Get Well</option>
                                <option value="Graduation">Graduation</option>
                                <option value="Happy Hanukkah">Happy Hanukkah</option>
                                <option value="Happy Holidays">Happy Holidays</option>
                                <option value="Holiday">Holiday</option>
                                <option value="Just Because">Just Because</option>
                                <option value="Just Like That">Just Like That</option>
                                <option value="Maternity">Maternity</option>
                                <option value="Memorial">Memorial</option>
                                <option value="New Baby">New Baby</option>
                                <option value="Other">Other</option>
                                <option value="Prom">Prom</option>
                                <option value="Retirement">Retirement</option>
                                <option value="Romance">Romance</option>
                                <option value="Sympathy">Sympathy</option>
                                <option value="Thank You">Thank You</option>
                                <option value="Thanksgiving">Thanksgiving</option>
                                <option value="Thinking of You">Thinking of You</option>
                                <option value="Wedding">Wedding</option>
                              </select>
                            </div>
                          </div>
                          <br />
                          <div className="row">
                            <div className="col-md-6">
                              <p>Source Code</p>
                              <select className="form-control other-select">
                                <option>Select Source</option>
                              </select>
                            </div>
                            <div className="col-md-6">
                            </div>
                          </div>

                        </div>

                        <div className="col-md-5">
                          <div className="bill-div">
                            <div className="row">
                              <div className="col-md-6 text-right bill-div-inner">
                                <br />
                                <p>Sub Total</p>
                              </div>
                              <div className="col-md-6 text-center">
                                <br />
                                <span>$
                                  {getSubTotal()}
                                </span>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-md-6 text-right bill-div-inner">
                                <p>Tax</p>
                              </div>
                              <div className="col-md-6 text-center">
                                <span>${tax}</span>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-md-6 text-right bill-div-inner">
                                <p>Discount</p>
                              </div>
                              <div className="col-md-6 text-center">
                                <span>${getTotalDiscount()}</span>
                              </div>
                            </div>
                            <div className="bill-decor-line"></div>
                            <br />
                            <div className="row">
                              <div className="col-md-6 text-right bill-div-inner">
                                <p>Grand Total</p>
                              </div>
                              <div className="col-md-6 text-center">
                                <span>$
                                  {getGrandTotal()}
                                </span>
                              </div>
                            </div>
                          </div>

                        </div>
                        <button className="btn btn-primary my-4 offset-10" type="submit"
                          onClick={handleOrder}
                        >Submit an Order</button>
                      </div>

                    </form>

                  </div>

                </div>
              </div>

            </div>
          </div>
          <Rightnav />

        </div>

      </section>

      <ToastContainer />
    </>
  )
}