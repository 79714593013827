import "date-fns";
import React, { useEffect, useState } from 'react'
import Form from 'react-bootstrap/Form'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import DateFnsUtils from "@date-io/date-fns";
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker
} from "@material-ui/pickers";
import Table from 'react-bootstrap/Table'
import InputGroup from 'react-bootstrap/InputGroup'
import FormControl from 'react-bootstrap/FormControl'
import ButtonGroup from 'react-bootstrap/ButtonGroup'
import Button from 'react-bootstrap/Button'
import { useSelector, useDispatch } from "react-redux";
import { addItem } from "../actions";
import { useHistory } from "react-router";
import { toast, ToastContainer } from "react-toastify";
import Rightnav from './Responsiverightnav';
import Header from "./Header";
import { FaCalendar } from "react-icons/fa";

const Neworder = () => {

    const dispatch = useDispatch();
    const history = useHistory();
    const [searchEmail, setSearchEmail] = useState('');

    const loginObject = JSON.parse(localStorage.getItem('login'));
    let token = loginObject.jwt;
    let shopId = loginObject.shop_id;
    let userId = loginObject.user_id;
    let shopName = loginObject.shopname;

    const [shopItems, setShopItems] = useState([]);
    const itemsInBasket = useSelector((state) => state.itemReducer);
    console.log("Items in Basket: ", itemsInBasket);

    useEffect(() => {
        // localStorage.setItem('CustomerId', '');
        getItemList();

    }, []);
    const getItemList = () => {
        const url = "https://apifloragen.gamingpandastudios.com/api/item_list";
        const requestBody = {
            jwt: token,
        }

        try {
            fetch(url, {
                method: "POST",
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(requestBody),
            })
                .then((res) => res.json())
                .then((data) => {
                    console.log("Item Info: ", data.records);
                    setShopItems(data.records);
                })
                .catch((e) => console.log(e));
        }
        catch (e) {
            console.log(e);
        }
    }

    const [paymentStartDate, setPaymentStartDate] = useState(new Date());


    const [selectedDate, setSelectedDate] = useState(new Date());

    // const [formValues, setFormValues] = useState([{ item_id: "", description: "", qty: 0, price: "", discount_amt: "", discount_percentage: "" }])

    const [iscash, setIsCash] = useState(false);
    const [isCreditcard, setIsCreditcard] = useState(false);
    const [ischeck, setIsCheck] = useState(false);
    const [isDonation, setIsDonation] = useState(false);
    const [isGiftcard, setIsGiftcard] = useState(false);
    const [isHouseAcc, setIsHouseAcc] = useState(false);
    const [isSplitpay, setIsSplitpay] = useState(false);
    const [paymentType, setPaymentType] = useState(null);

    // Customer Section states
    const [c_fname, setC_fname] = useState('');
    const [c_lname, setC_lname] = useState('');
    const [c_companyname, setC_companyname] = useState('');
    const [c_address1, setC_address1] = useState('');
    const [c_zip, setC_zip] = useState('');
    const [c_phone1, setC_phone1] = useState('');
    const [c_ext1, setC_ext1] = useState('');
    const [c_email, setC_email] = useState('');
    const [c_address2, setC_address2] = useState('');
    const [c_city, setC_city] = useState('');
    const [c_phone2, setC_phone2] = useState('');
    const [c_state, setC_state] = useState('');
    const [c_ext2, setC_ext2] = useState('');

    const [isCreate, setIsCreate] = useState(true);

    // Recipient Section states
    const [r_fname, setR_fname] = useState('');
    const [r_address1, setR_address1] = useState('');
    const [r_zip, setR_zip] = useState('');
    const [r_country, setR_country] = useState('');
    const [r_location, setR_location] = useState('');
    const [startDate, setStartDate] = useState(new Date());
    const [r_lname, setR_lname] = useState('');
    const [r_address2, setR_address2] = useState('');
    const [r_city, setR_city] = useState('');
    const [r_phone1, setR_phone1] = useState('');
    const [r_ext1, setR_ext1] = useState('');
    const [r_zone, setR_zone] = useState('');
    const [r_deliveryon, setR_deliveryon] = useState('');
    const [r_attention, setR_attention] = useState('');
    const [r_apt, setR_apt] = useState('');
    const [r_state, setR_state] = useState('');
    const [r_phone2, setR_phone2] = useState('');
    const [r_ext2, setR_ext2] = useState('');
    const [r_options, setR_options] = useState('');
    const [r_time, setR_time] = useState('');

    // Order Details Section
    const [o_occasion, setO_occasion] = useState('');
    const [o_isPrint, setO_isPrint] = useState(false);
    const [o_cardmsg, setO_cardmsg] = useState('');

    //Product Section States
    const [pr_spcInst, setPr_spcInst] = useState('');
    const [pr_drvInst, setPr_drvInst] = useState('');
    const [pr_custNote, setPr_custNote] = useState('');
    const [pr_taxtype, setPr_taxtype] = useState(0);
    const [pr_sCode, setPr_sCode] = useState('');
    const [pr_taxid, setPr_taxid] = useState('');
    const [pr_custType, setPr_custType] = useState('');

    const [man_itemcode, setMan_itemcode] = useState();
    const [man_itemname, setMan_itemname] = useState();
    const [man_discription, setMan_discription] = useState();
    const [man_qty, setMan_qty] = useState();
    const [man_price, setMan_price] = useState();
    const [man_discount, setMan_discount] = useState();
    const [man_discountPercent, setMan_discountPercent] = useState();

    const handleItemCode = (s_item) => {
        const selectedItem = shopItems.find(item => item.itemid == s_item);
        // console.log("SelectedItem is:",selectedItem);
        selectedItem ? setMan_itemcode(selectedItem.itemid) : setMan_itemcode('');
        selectedItem ? setMan_itemname(selectedItem.itemname) : setMan_itemname('');
        selectedItem ? setMan_discription(selectedItem.itemdesc) : setMan_discription('');
        selectedItem ? setMan_qty(1) : setMan_qty('');
        selectedItem ? setMan_price(selectedItem.price) : setMan_price('');
        selectedItem ? setMan_discount(0.00) : setMan_discount();
        selectedItem ? setMan_discountPercent(0.00) : setMan_discountPercent();

    }

    const handleSearchEmail = (e) => {
        e.preventDefault();
        const loginObject = JSON.parse(localStorage.getItem('login'));
        let token = loginObject.jwt;
        let shopId = loginObject.shop_id;
        const url = "https://apifloragen.gamingpandastudios.com/api/customer_search";
        const requestBody = {
            email: searchEmail,
            shop_id: shopId,
            jwt: token
        }
        try {
            fetch(url, {
                method: "POST",
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(requestBody),
            })
                .then((res) => res.json())
                .then((data) => {
                    console.log("Searched Customer is: ", data.records);
                    setC_companyname(data.records[0].companyname);
                    setC_address2(data.records[0].address2);
                    //   setM_country(data.records[0].country);
                    setC_fname(data.records[0].firstname);
                    setC_zip(data.records[0].zip);
                    setC_address1(data.records[0].address1);
                    setC_phone1(data.records[0].phone1);
                    // setM_credit(data.records[0].store_credit);
                    setC_lname(data.records[0].lastname);
                    setC_city(data.records[0].city);
                    setC_phone2(data.records[0].phone2);
                    setC_state(data.records[0].state);
                    setPr_custType(data.records[0].customer_type);
                    // setIsCreditApproved(data.records[0].credit_approved == "1" ? true : false);
                    setC_email(searchEmail);
                    // localStorage.setItem('postRequestForNewCustomer', 'false');
                    localStorage.setItem('CustomerId', data.records[0].cust_id);
                    setSearchEmail('');
                    setIsCreate(true);
                    toast.success("The customer details are fetched.", {
                        position: "top-center"
                    })
                    // setActualCustomer(dalocalStorage.getItem('CustomerId')ta.records[0].cust_id);
                })
                .catch((e) => {
                    setC_companyname('');
                    setC_address2('');
                    // setM_country('');
                    setC_fname('');
                    setC_zip('');
                    setC_address1('');
                    setC_phone1('');
                    // setM_credit('');
                    setC_lname('');
                    setC_city('');
                    setC_phone2('');
                    setC_state('');
                    setPr_custType('');
                    // setIsCreditApproved(false);
                    setC_email('');
                    // localStorage.setItem('postRequestForNewCustomer', 'true');
                    console.log(e)
                    setIsCreate(false);
                    localStorage.setItem('CustomerId', null);
                    toast.warning("This user does not exists.", {
                        position: "top-center"
                    })
                });
        }
        catch (e) {
            console.log(e);
        }
    }

    const handleCreateCustomer = (e) => {
        e.preventDefault();
        const loginObject = JSON.parse(localStorage.getItem('login'));
        let token = loginObject.jwt;
        let shopId = loginObject.shop_id;
        const url = "https://apifloragen.gamingpandastudios.com/api/create_customer";
        const requestBody = {
            firstname: c_fname,
            lastname: c_lname,
            companyname: c_companyname,
            email: c_email,
            address1: c_address1,
            address2: c_address2,
            city: c_city,
            state: c_state,
            country: '',
            zip: c_zip,
            phone1: c_phone1,
            phone2: c_phone2,
            shop_id: shopId,
            customer_type: pr_custType,
            store_credit: '',
            credit_approved: "0",
            jwt: token
        }

        try {
            fetch(url, {
                method: "POST",
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(requestBody),
            })
                .then((res) => res.json())
                .then((data) => {
                    console.log("Customer Info: ", data);
                    toast.success("Customer is created", {
                        position: "top-center"
                    })
                    setIsCreate(true);
                    setTimeout(() => {
                        toast.success("Search for customer from its email and click Add Customer", {
                            position: "top-center"
                        })
                    }, 6000);

                })
                .catch((e) => {
                    toast.error(e, {
                        position: "top-center"
                    })
                    setIsCreate(false);
                });
        }
        catch (e) {
            toast.error(e, {
                position: "top-center"
            });
        }
    }

    const handleItemSubmit = (e) => {
        e.preventDefault();
        const item = {
            itemid: man_itemcode,
            itemname: man_itemname,
            itemdesc: man_discription,
            itemqty: man_qty,
            price: man_price,
            discount_amount: parseFloat(man_discount),
            discount_percentage: parseFloat(man_discountPercent),
        }

        console.log("New Item is:", item);
        dispatch(addItem({ item }));
    }

    function getSubTotal() {
        const sub = itemsInBasket.itemsInBasket?.reduce((sub, { price, itemqty }) => sub + parseFloat(price) * parseFloat(itemqty), 0);
        return sub;
    }

    function getTotalDiscount() {
        const sub = itemsInBasket.itemsInBasket?.reduce((sub, { discount_percentage, price, itemqty }) => sub + (parseFloat(discount_percentage / 100) * parseFloat(price) * parseFloat(itemqty)), 0);
        return sub;
    }

    function getTotalDiscountPercent() {
        const sub = itemsInBasket.itemsInBasket?.reduce((sub, { discount_percentage }) => sub + parseFloat(discount_percentage), 0);
        return sub;
    }

    function getGrandTotal() {
        return getSubTotal() + parseFloat(pr_taxtype) - getTotalDiscount();
    }    

    let handleSubmit = (event) => {
        event.preventDefault();
        const finalUrl = "https://apifloragen.gamingpandastudios.com/api/create_order";
        const requestBody = {
            invoice: "INV24112021",
            user_id: userId,
            shop_id: shopId,
            firstname: c_fname,
            lastname: c_lname,
            address1: c_address1,
            address2: c_address2,
            city: c_city,
            state: c_state,
            zipcode: c_zip,
            ordertype: "Web Order",
            occasion: o_occasion,
            source_code: pr_sCode,
            dlv_type: "Delivery",
            mop: paymentType,
            coupon_code: "",
            tax_id: pr_taxid,
            discount_percentage: getTotalDiscountPercent(),
            discount_amount: getTotalDiscount(),
            sub_total: getSubTotal(),
            tax_amount: pr_taxtype,
            grand_total: getGrandTotal(),
            customerid: localStorage.getItem('CustomerId'),
            dlvdate: startDate,
            dlvtime: "10:20:28",
            DELIVERYCHG: "25",
            RELAYCHG: "10",
            CardMsg: o_cardmsg,
            SpecialInts: pr_spcInst,
            driver_insts: pr_drvInst,
            customer_insts: pr_custNote,
            designer_insts: "",
            delivery_on: r_deliveryon,
            delivery_status: "pending",
            RECIPIENT_FIRST_NAME: r_fname,
            RECIPIENT_LAST_NAME: r_lname,
            attention: r_attention,
            Address1: r_address1,
            Address2: r_address2,
            apt_floor: r_apt,
            location: r_location,
            zone: r_zone,
            options: r_options,
            City: r_city,
            State: r_state,
            ZIPCode: r_zip,
            Country: r_country,
            ext1: r_ext1,
            phone1: r_phone1,
            ext2: r_ext2,
            phone2: r_phone2,
            LoadedDateTime: "",
            RouteControlNum: "",
            Shop_Id: shopId,
            Driver_Id: "",
            DelCode: "",
            Header_ID: "",
            items: itemsInBasket.itemsInBasket.map((item) => ({
                itemid: item.itemid,
                itemqty: item.itemqty,
                price: item.price,
                discount_amount: item.discount_amount,
                discount_percentage: item.discount_percentage
            })),
            // items: itemsInBasket,
            jwt: token
        }

        console.log("The request body is:", requestBody);
        console.log("Items in The Basket:", itemsInBasket);
        try {
            fetch(finalUrl, {
                method: "POST",
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(requestBody),
            })
                .then((res) => res.json())
                .then((data) => {
                    console.log("Item Info: ", data);
                    toast.success("Your order has been created.", {
                        position: "top-center"
                    })

                })
                .catch((e) => {
                    toast.error(e, {
                        position: "top-center"
                    })
                });
            // history.push('/home');
        }
        catch (e) {
            toast.error(e, {
                position: "top-center"
            })
        }

    }

    const handlePaymentType = (e) => {
        setPaymentType(e.target.value);

        (e.target.value == 'cash') ? setIsCash(true) : setIsCash(false);
        (e.target.value == 'creditCard') ? setIsCreditcard(true) : setIsCreditcard(false);
        (e.target.value == 'check') ? setIsCheck(true) : setIsCheck(false);
        (e.target.value == 'donation') ? setIsDonation(true) : setIsDonation(false);
        (e.target.value == 'giftcard') ? setIsGiftcard(true) : setIsGiftcard(false);
        (e.target.value == 'houseAcc') ? setIsHouseAcc(true) : setIsHouseAcc(false);
        (e.target.value == 'splitPay') ? setIsSplitpay(true) : setIsSplitpay(false);
    }

    console.log("Payment Type is: ", paymentType);

    return (
        <div className="neworder-container">
            <Header />
            <Form>
                <div className="row">

                    <div className="col-md-8">
                        <div className="row my-3">
                            <div className="col-md-5">
                                <div className="container">
                                    <p className="p-1 my-2" style={{ backgroundColor: "#cce0ef" }}>Customer</p>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <InputGroup className="mb-3">
                                                <Form.Control type="email" placeholder="Enter Customer Email" value={searchEmail} onChange={(e) => setSearchEmail(e.target.value)} />
                                                <Button variant="primary" className="btn" type="submit" onClick={handleSearchEmail}>
                                                    Search
                                                </Button>&nbsp;
                                                <button className="btn btn-info btn-sm" type="submit" onClick={handleCreateCustomer} disabled={isCreate}>
                                                    New Customer
                                                </button>
                                            </InputGroup>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-md-6">
                                            <Form.Control type="text" placeholder="First Name" className="mb-1"
                                                value={c_fname} onChange={(e) => setC_fname(e.target.value)}
                                            />
                                            <Form.Control type="text" placeholder="Company Name" className="mb-1"
                                                value={c_companyname} onChange={(e) => setC_companyname(e.target.value)}
                                            />
                                            <Form.Control type="text" placeholder="Address1" className="mb-1"
                                                value={c_address1} onChange={(e) => setC_address1(e.target.value)}
                                            />
                                            <Form.Control type="text" placeholder="Zip / Postal Code" className="mb-1"
                                                value={c_zip} onChange={(e) => setC_zip(e.target.value)}
                                            />
                                            <div className="row">
                                                <div className="col-md-7">
                                                    <Form.Control type="text" placeholder="Phone" className="mb-1"
                                                        value={c_phone1} onChange={(e) => setC_phone1(e.target.value)}
                                                    />
                                                </div>
                                                <div className="col-md-5">
                                                    <Form.Control type="text" placeholder="Ext" className="mb-1"
                                                        value={c_ext1} onChange={(e) => setC_ext1(e.target.value)}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <Form.Control type="text" placeholder="Last Name" className="mb-1"
                                                value={c_lname} onChange={(e) => setC_lname(e.target.value)}
                                            />

                                            <Form.Control type="email" placeholder="Email" className="mb-1"
                                                value={c_email} onChange={(e) => setC_email(e.target.value)}
                                            />
                                            <Form.Control type="text" placeholder="Address2" className="mb-1"
                                                value={c_address2} onChange={(e) => setC_address2(e.target.value)}
                                            />
                                            <div className="row">
                                                <div className="col-md-7">
                                                    <Form.Control type="text" placeholder="City" className="mb-1"
                                                        value={c_city} onChange={(e) => setC_city(e.target.value)}
                                                    />
                                                    <Form.Control type="text" placeholder="Alt Phone" className="mb-1"
                                                        value={c_phone2} onChange={(e) => setC_phone2(e.target.value)}
                                                    />
                                                </div>
                                                <div className="col-md-5">
                                                    <Form.Control type="text" placeholder="Province / State" className="mb-1"
                                                        value={c_state} onChange={(e) => setC_state(e.target.value)}
                                                    />
                                                    <Form.Control type="text" placeholder="Ext" className="mb-1"
                                                        value={c_ext2} onChange={(e) => setC_ext2(e.target.value)}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div className="col-md-7">
                                <div className="container">
                                    <p className="p-1 my-2" style={{ backgroundColor: "#f0d4be" }}>Recipient</p>

                                    <div className="row">
                                        <div className="col-md-4">
                                            <Form.Control type="text" placeholder="First Name" className="mb-1"
                                                value={r_fname} onChange={(e) => setR_fname(e.target.value)}
                                            />
                                            <Form.Control type="text" placeholder="Address1" className="mb-1"
                                                value={r_address1} onChange={(e) => setR_address1(e.target.value)}
                                            />
                                            <Form.Control type="text" placeholder="Zip / Postal Code" className="mb-1"
                                                value={r_zip} onChange={(e) => setR_zip(e.target.value)}
                                            />
                                            <Form.Select aria-label="Default select example" className="form-control mb-1"
                                                value={r_country} onChange={(e) => setR_country(e.target.value)}
                                            >
                                                <option value="US">United State</option>
                                                <option value="IN">India</option>
                                            </Form.Select>
                                            <Form.Select aria-label="Default select example" className="form-control mb-1"
                                                value={r_location} onChange={(e) => setR_location(e.target.value)}
                                            >
                                                <option>--Select Location--</option>
                                                <option value="Apartment">Apartment</option>
                                                <option value="Business">Business</option>
                                                <option value="Church">Church</option>
                                                <option value="Funeral Home">Funeral Home</option>
                                                <option value="Home">Home</option>
                                                <option value="Hospital">Hospital</option>
                                                <option value="Hotel">Hotel</option>
                                                <option value="Nursing Home">Nursing Home</option>
                                                <option value="Restaurant">Restaurant</option>
                                                <option value="School">School</option>
                                                <option value="Unknown">Unknown</option>
                                            </Form.Select>

                                            <div className="row">
                                                <div className="col-md-1">
                                                    <FaCalendar />
                                                </div>
                                                <div className="col-md-10">
                                                    <DatePicker className="form-control mb-1" selected={startDate} onChange={(date) => setStartDate(date)} placeholder="Date" />
                                                </div>

                                            </div>



                                        </div>
                                        <div className="col-md-4">
                                            <Form.Control type="text" placeholder="Last Name" className="mb-1"
                                                value={r_lname} onChange={(e) => setR_lname(e.target.value)}
                                            />

                                            <Form.Control type="text" placeholder="Address2" className="mb-1"
                                                value={r_address2} onChange={(e) => setR_address2(e.target.value)}
                                            />

                                            <Form.Control type="text" placeholder="City" className="mb-1"
                                                value={r_city} onChange={(e) => setR_city(e.target.value)}
                                            />
                                            <div className="row">
                                                <div className="col-md-7">
                                                    <Form.Control type="text" placeholder="Phone 1" className="mb-1"
                                                        value={r_phone1} onChange={(e) => setR_phone1(e.target.value)}
                                                    />
                                                </div>
                                                <div className="col-md-5">

                                                    <Form.Control type="text" placeholder="Ext" className="mb-1"
                                                        value={r_ext1} onChange={(e) => setR_ext1(e.target.value)}
                                                    />


                                                </div>
                                            </div>
                                            <Form.Select aria-label="Default select example" className="form-control mb-1"
                                                value={r_zone} onChange={(e) => setR_zone(e.target.value)}
                                            >
                                                <option>--Select Zone--</option>
                                                <option value="Carnelian Bay">Carnelian Bay</option>
                                                <option value="Carnelian Bay-96140">Carnelian Bay-96140</option>
                                                <option value="Tahoe City">Tahoe City</option>
                                            </Form.Select>

                                            <Form.Select aria-label="Default select example" className="form-control mb-1"
                                                value={r_deliveryon} onChange={(e) => setR_deliveryon(e.target.value)}
                                            >
                                                <option>--Select Delivery On--</option>
                                                <option value="After">After</option>
                                                <option value="Around">Around</option>
                                                <option value="Before">Before</option>
                                                <option value="Exactly At">Exactly At</option>
                                                <option value="AM">AM</option>
                                                <option value="PM">PM</option>
                                                <option value="Any">Any</option>
                                            </Form.Select>
                                        </div>
                                        <div className="col-md-4">
                                            <Form.Control type="text" placeholder="Attention" className="mb-1"
                                                value={r_attention} onChange={(e) => setR_attention(e.target.value)}
                                            />
                                            <Form.Control type="text" placeholder="Apt / Floor" className="mb-1"
                                                value={r_apt} onChange={(e) => setR_apt(e.target.value)}
                                            />
                                            <Form.Control type="text" placeholder="Province / State" className="mb-1"
                                                value={r_state} onChange={(e) => setR_state(e.target.value)}
                                            />
                                            <div className="row">
                                                <div className="col-md-7">
                                                    <Form.Control type="text" placeholder="Phone 2" className="mb-1"
                                                        value={r_phone2} onChange={(e) => setR_phone2(e.target.value)}
                                                    />
                                                </div>
                                                <div className="col-md-5">
                                                    <Form.Control type="text" placeholder="Ext" className="mb-1"
                                                        value={r_ext2} onChange={(e) => setR_ext2(e.target.value)}
                                                    />
                                                </div>
                                            </div>
                                            <Form.Select aria-label="Default select example" className="form-control mb-1"
                                                value={r_options} onChange={(e) => setR_options(e.target.value)}
                                            >
                                                <option>--Select Options--</option>
                                                <option value="regular">Option 1</option>
                                                <option value="regular">Option 2</option>
                                                <option value="regular">Option 3</option>
                                            </Form.Select>

                                            <div className="row">
                                                <div className="col-md-9">
                                                    <Form.Control type="text" placeholder="Time" className="mb-1"
                                                        value={r_time} onChange={(e) => setR_time(e.target.value)}
                                                    />
                                                </div>
                                                <div className="col-md-3">
                                                    <Button variant="danger" type="submit">
                                                        X
                                                    </Button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div className="row my-3">
                            <div className="col-md-12">
                                <div className="mx-3">
                                    <p className="p-1 my-2" style={{ backgroundColor: "#e0ccc7" }}>Order Details</p>

                                    <div className="row">
                                        <div className="col-md-3">
                                            <Form.Select aria-label="Default select example" className="form-control mb-3"
                                                value={o_occasion} onChange={(e) => setO_occasion(e.target.value)}
                                            >
                                                <option>--Choose Occasion--</option>
                                                <option value="Anniversary">Anniversary</option>
                                                <option value="Birthday">Birthday</option>
                                                <option value="Boss Day">Boss Day</option>
                                                <option value="Business Gifts">Business Gifts</option>
                                                <option value="Christmas">Christmas</option>
                                                <option value="Congratulations">Congratulations</option>
                                                <option value="Event">Event</option>
                                                <option value="Fund Raiser">Fund Raiser</option>
                                                <option value="Get Well">Get Well</option>
                                                <option value="Graduation">Graduation</option>
                                                <option value="Happy Hanukkah">Happy Hanukkah</option>
                                                <option value="Happy Holidays">Happy Holidays</option>
                                                <option value="Holiday">Holiday</option>
                                                <option value="Just Because">Just Because</option>
                                                <option value="Just Like That">Just Like That</option>
                                                <option value="Maternity">Maternity</option>
                                                <option value="Memorial">Memorial</option>
                                                <option value="New Baby">New Baby</option>
                                                <option value="Other">Other</option>
                                                <option value="Prom">Prom</option>
                                                <option value="Retirement">Retirement</option>
                                                <option value="Romance">Romance</option>
                                                <option value="Sympathy">Sympathy</option>
                                                <option value="Thank You">Thank You</option>
                                                <option value="Thanksgiving">Thanksgiving</option>
                                                <option value="Thinking of You">Thinking of You</option>
                                                <option value="Wedding">Wedding</option>

                                            </Form.Select>

                                            <Form.Check inline label="Print Ticket" checked={o_isPrint} onChange={(e) => setO_isPrint(e.target.checked)} type="checkbox" />
                                        </div>
                                        <div className="col-md-2">
                                            <a href="#">
                                                {/* <small><b>View ShortCodes</b></small> */}
                                            </a>
                                        </div>
                                        <div className="col-md-7">
                                            <Form.Control as="textarea" rows={3} placeholder="Card Message"
                                                value={o_cardmsg} onChange={(e) => setO_cardmsg(e.target.value)}
                                            />
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>

                        <div className="row my-3">
                            <div className="col-md-12">
                                <div className="mx-3">
                                    <p className="p-1 my-2" style={{ backgroundColor: "#c2cace" }}>Products</p>
                                    <div className="row ml-1 pb-3">
                                        <div className="col-md-12 pl-0 ">

                                            <div className="other-page-inner-right-section">
                                                <form>
                                                    <div className="row">
                                                        <button className="action-button-1" type="submit"
                                                            onClick={handleItemSubmit}
                                                        >
                                                            <img src="assets/images/icons/plus.png" />
                                                        </button>
                                                        <button className="action-button-2">
                                                            <img src="assets/images/icons/refresh-red.png" />
                                                        </button>
                                                        <div className="col-md-2">
                                                            <p>Item Code</p>
                                                            <input type="text" className="form-control other-select" name="" value={man_itemcode} onChange={(e) => handleItemCode(e.target.value)} placeholder="Item Code" />
                                                        </div>
                                                        <div className="col-md-3">
                                                            <p>Description</p>
                                                            <input type="text" className="form-control other-select" name="" value={man_discription} placeholder="Description" />
                                                        </div>
                                                        <div className="col-md-1">
                                                            <p>Qty</p>
                                                            <input type="text" className="form-control other-select" name="" value={man_qty} onChange={(e) => setMan_qty(e.target.value)} placeholder="1" />
                                                        </div>
                                                        <div className="col-md-2">
                                                            <p>Price</p>
                                                            <input type="text" className="form-control other-select" name="" value={man_price}
                                                                placeholder="Price" />
                                                        </div>
                                                        <div className="col-md-2">
                                                            <p>Discount</p>
                                                            <input type="text" className="form-control other-select" name="" value={(man_discountPercent > 0) ? (((man_discountPercent) / 100) * (man_price * man_qty)) : man_discount} onChange={(e) => setMan_discount(e.target.value)} placeholder="Discount" />
                                                        </div>
                                                        <div className="col-md-2">
                                                            <p>Discount%</p>
                                                            <input type="text" className="form-control other-select" name="" value={man_discountPercent} onChange={(e) => setMan_discountPercent(e.target.value)} placeholder="Discount%" />
                                                        </div>
                                                    </div>
                                                </form>



                                                <div className="item-details mt-4">
                                                    <div className="tab-content-div pt-0">
                                                        <div className="table-responsive-sm">
                                                            <table className="table">
                                                                <thead>
                                                                    <tr>
                                                                        <th scope="col" className="blue">Item Code</th>
                                                                        <th scope="col" className="blue">Description</th>
                                                                        <th scope="col" className="blue">Qty</th>
                                                                        <th scope="col" className="blue">Price</th>
                                                                        <th scope="col" className="blue">Discount</th>
                                                                        <th scope="col" className="blue">Discount%</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {
                                                                        itemsInBasket.itemsInBasket.map((item) => {
                                                                            let quantity = 1;
                                                                            item.discount_amount = (item.discount_percentage / 100) * (item.price * item.itemqty) ? (item.discount_percentage / 100) * (item.price * item.itemqty) : 0;
                                                                            return (
                                                                                <tr>
                                                                                    <td>{item.itemid}</td>
                                                                                    <td>{item.itemdesc}</td>
                                                                                    <td>{item.itemqty ? item.itemqty : quantity}</td>
                                                                                    <td>{item.price}</td>
                                                                                    <td>{item.discount_amount}</td>
                                                                                    <td>{item.discount_percentage ? item.discount_percentage : 0}</td>
                                                                                </tr>
                                                                            )
                                                                        })
                                                                    }

                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>

                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-md-3">
                                            <Form.Control as="textarea" rows={5} placeholder="Special Instructions"
                                                value={pr_spcInst} onChange={(e) => setPr_spcInst(e.target.value)}
                                            />
                                        </div>
                                        <div className="col-md-3">
                                            <Form.Control as="textarea" rows={5} placeholder="Driver Instructions"
                                                value={pr_drvInst} onChange={(e) => setPr_drvInst(e.target.value)}
                                            />
                                        </div>
                                        <div className="col-md-3">
                                            <Form.Control as="textarea" rows={5} placeholder="Customer Private Notes"
                                                value={pr_custNote} onChange={(e) => setPr_custNote(e.target.value)}
                                            />
                                        </div>
                                        <div className="col-md-3">
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <Form.Select aria-label="Default select example" className="form-control mb-3"
                                                        value={pr_taxtype} onChange={(e) => setPr_taxtype(e.target.value)}
                                                    >
                                                        <option>--Tax Type--</option>
                                                        <option value="8.50" key="ST">Standard Tax</option>
                                                        <option value="0" key="TE">Tax Exemption</option>
                                                    </Form.Select>

                                                    <Form.Group className="mb-3">
                                                        <Form.Label>Source Code</Form.Label>
                                                        <Form.Select aria-label="Default select example" className="form-control mb-3"
                                                            value={pr_sCode} onChange={(e) => setPr_sCode(e.target.value)}
                                                        >
                                                            <option>--Select Source--</option>
                                                            <option value="Friend" >Friend</option>
                                                            <option value="Newspaper">Newspaper</option>
                                                            <option value="Social Media">Social Media</option>
                                                        </Form.Select>
                                                    </Form.Group>
                                                </div>
                                                <div className="col-md-6">
                                                    <Form.Control type="text" className="mb-3" placeholder="Tax ID"
                                                        value={pr_taxid} onChange={(e) => setPr_taxid(e.target.value)}
                                                    />

                                                    <Form.Group className="mb-3">
                                                        <Form.Label>Cust Type</Form.Label>
                                                        <Form.Select aria-label="Default select example" className="form-control mb-3"
                                                            value={pr_custType} onChange={(e) => setPr_custType(e.target.value)}
                                                        >
                                                            <option>--Select--</option>
                                                            <option value="Wire Service" >Wire Service</option>
                                                            <option value="Florist">Florist</option>
                                                            <option value="Corporate">Corporate</option>
                                                            <option value="Other Location">Other Location</option>
                                                            <option value="Staff">Staff</option>
                                                            <option value="Retail">Retail</option>
                                                        </Form.Select>
                                                    </Form.Group>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="container">
                            <p className="p-1 my-2 text-white" style={{ backgroundColor: "#388aaf" }}>Payment</p>
                            <div className="row">
                                <div className="col-md-12">
                                    <Form.Select aria-label="Default select example" style={{ borderLeft: "5px solid red" }} className="form-control mb-1"
                                        value={paymentType} onChange={handlePaymentType}
                                    >
                                        <option>--Select Payment Type--</option>
                                        <option value="paidOutside">Paid Outside Florogen</option>
                                        <option value="creditCard">Credit Card</option>
                                        <option value="cod">COD- Cash On Delivery</option>
                                        <option value="check">Check</option>
                                        <option value="cash">Cash</option>
                                        <option value="donation">Donation</option>
                                        <option value="giftcard">Gift Card</option>
                                        <option value="houseAcc">Invoice/House Account</option>
                                        <option value="splitPay">Split Payment</option>
                                    </Form.Select>
                                </div>
                                {
                                    iscash && <div className="container">
                                        <Form.Control type="text" className="mb-1" placeholder="0.00"
                                        //   onChange={(e) => setM_lname(e.target.value)}
                                        />
                                        <Form.Select aria-label="Default select example" className="form-control mb-1"
                                        //  value={m_customerType} onChange={(e) => setM_customerType(e.target.value)}
                                        >
                                            <option>Cash Register 1</option>
                                        </Form.Select>
                                        <small className="text-info">Change: $0.00</small>
                                    </div>
                                }

                                {
                                    isCreditcard && <div className="container">
                                        <div className="row mb-1">
                                            <div className="col-md-6">
                                                <Form.Control type="text" placeholder="First Name"
                                                //   onChange={(e) => setM_lname(e.target.value)}
                                                />
                                            </div>
                                            <div className="col-md-6">
                                                <Form.Check inline label="Card Present" type="checkbox" className="pt-1" />
                                            </div>
                                        </div>
                                        <Form.Control type="text" placeholder="Last Name" className="mb-1"
                                        //   onChange={(e) => setM_lname(e.target.value)}
                                        />

                                        <div className="row mb-1">
                                            <div className="col-md-6">
                                                <Form.Control type="text" placeholder="CC Number"
                                                //   onChange={(e) => setM_lname(e.target.value)}
                                                />
                                            </div>
                                            <div className="col-md-6">
                                                <Form.Check inline label="Don't Save" type="checkbox" className="pt-1" />
                                            </div>
                                        </div>

                                        <div className="row mb-1">
                                            <div className="col-md-6">
                                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                    <KeyboardDatePicker className="form-control"
                                                        disableToolbar
                                                        format="MM/yyyy"
                                                        views={["year", "month"]}
                                                        value={selectedDate}
                                                        onChange={(date) => setSelectedDate(date)}
                                                        KeyboardButtonProps={{
                                                            "aria-label": "change date"
                                                        }}
                                                    />
                                                </MuiPickersUtilsProvider>
                                            </div>
                                            <div className="col-md-6">
                                                <Form.Control type="text" placeholder="CCV"
                                                //   onChange={(e) => setM_lname(e.target.value)}
                                                />
                                            </div>
                                        </div>

                                        <Form.Control type="text" placeholder="Zip / Postal Code" className="mb-1"
                                        //   onChange={(e) => setM_lname(e.target.value)}
                                        />

                                        <Form.Control type="text" placeholder="City" className="mb-1"
                                        //   onChange={(e) => setM_lname(e.target.value)}
                                        />

                                        <Form.Control type="text" placeholder="State" className="mb-1"
                                        //   onChange={(e) => setM_lname(e.target.value)}
                                        />

                                        <Form.Select aria-label="Default select example" className="form-control mb-3"
                                        //  value={m_customerType} onChange={(e) => setM_customerType(e.target.value)}
                                        >
                                            <option>--Country--</option>
                                            <option value="regular">India</option>
                                            <option value="regular">USA</option>
                                            <option value="regular">UK</option>
                                        </Form.Select>

                                    </div>
                                }

                                {
                                    ischeck && <div className="container">
                                        <Form.Control type="text" className="mb-1" placeholder="Bank Name"
                                        //   onChange={(e) => setM_lname(e.target.value)}
                                        />
                                        <Form.Control type="text" className="mb-1" placeholder="Name on Check"
                                        //   onChange={(e) => setM_lname(e.target.value)}
                                        />
                                        <Form.Control type="text" className="mb-1" placeholder="Check Number"
                                        //   onChange={(e) => setM_lname(e.target.value)}
                                        />
                                        <Form.Select aria-label="Default select example" className="form-control mb-1"
                                        //  value={m_customerType} onChange={(e) => setM_customerType(e.target.value)}
                                        >
                                            <option>--Select Cash Registry--</option>
                                            <option>Cash Register 1</option>
                                        </Form.Select>

                                        <DatePicker className="form-control mb-1" selected={paymentStartDate} onChange={(date) => setPaymentStartDate(date)} />

                                        <Form.Control type="text" className="mb-1" placeholder="Account Number"
                                        //   onChange={(e) => setM_lname(e.target.value)}
                                        />
                                        <Form.Control type="text" className="mb-1" placeholder="Routing Number"
                                        //   onChange={(e) => setM_lname(e.target.value)}
                                        />
                                    </div>
                                }
                                {
                                    isDonation && <div className="container">
                                        <Form.Control type="text" className="mb-1" placeholder="EIN Number"
                                        //   onChange={(e) => setM_lname(e.target.value)}
                                        />
                                    </div>
                                }

                                {
                                    isGiftcard && <div className="container">
                                        <InputGroup className="mb-3">
                                            <Form.Control type="text" placeholder="Card Number" />
                                            <Button variant="primary" className="btn-sm" >
                                                Swipe Card
                                            </Button>
                                        </InputGroup>

                                        <Form.Control type="text" className="mb-1" placeholder="Customer Name"
                                        //   onChange={(e) => setM_lname(e.target.value)}
                                        />

                                        <Form.Control type="text" className="mb-1" placeholder="0.00"
                                        //   onChange={(e) => setM_lname(e.target.value)}
                                        />

                                        <Form.Control type="text" className="mb-1" placeholder="Gift Card Current Balance"
                                        //   onChange={(e) => setM_lname(e.target.value)}
                                        />
                                    </div>
                                }

                                {
                                    isHouseAcc && <div className="container">
                                        <Form.Control type="text" className="mb-1" placeholder="PO Number Details"
                                        //   onChange={(e) => setM_lname(e.target.value)}
                                        />
                                        <Form.Control type="text" className="mb-1" placeholder="Days for House Acc." value="15"
                                        //   onChange={(e) => setM_lname(e.target.value)}
                                        />
                                    </div>
                                }

                                {
                                    isSplitpay && <div className="container">
                                        <Form.Select aria-label="Default select example" className="form-control mb-1"
                                        //  value={m_customerType} onChange={(e) => setM_customerType(e.target.value)}
                                        >
                                            {/* <option>--Select Cash Registry--</option> */}
                                            <option>Cash Register 1</option>
                                        </Form.Select>
                                    </div>
                                }


                            </div>
                            <br />


                            <div className="row offset-2">
                                <div className="col-md-12 " >
                                    <div style={{ display: "flex", justifyContent: "space-around" }}>
                                        <p className="mt-2">Total</p> &nbsp; &nbsp; &nbsp;
                                        <Form.Control type="text" className="mb-1" value="0.00" disabled
                                            value={getSubTotal()}
                                        />
                                    </div>

                                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                                        <p className="mt-2">Coupon</p> &nbsp; &nbsp; &nbsp;
                                        <Form.Control type="text" className="mb-1" placeholder="Coupon Code"
                                        //   onChange={(e) => setM_lname(e.target.value)}
                                        />
                                    </div>

                                    <div className="row">
                                        <div className="col-md-6">
                                            <div style={{ display: "flex", justifyContent: "space-between" }}>
                                                <p className="mt-2">Disc(%)</p> &nbsp; &nbsp; &nbsp;
                                                <Form.Control type="text" value={getTotalDiscountPercent()}
                                                //   onChange={(e) => setM_lname(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div style={{ display: "flex", justifyContent: "space-between" }}>
                                                <p className="mt-2">Disc</p> &nbsp; &nbsp; &nbsp;
                                                <Form.Control type="text" value={getTotalDiscount()}
                                                //   onChange={(e) => setM_lname(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                                        <p style={{ fontSize: "0.8rem", fontWeight: "600" }}>Del&nbsp;Fee</p> &nbsp; &nbsp; &nbsp;
                                        <Form.Control type="text" value="0.00"
                                        //   onChange={(e) => setM_lname(e.target.value)}
                                        />
                                    </div>

                                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                                        <p className="mt-2">Relay</p> &nbsp; &nbsp; &nbsp;
                                        <Form.Control type="text" className="mb-1" value="0.00"
                                        //   onChange={(e) => setM_lname(e.target.value)}
                                        />
                                    </div>

                                    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                        <p className="mt-2">Tax</p> &nbsp; &nbsp; &nbsp;
                                        <Form.Control type="text" className="mb-1" value={pr_taxtype} disabled
                                        //   onChange={(e) => setM_lname(e.target.value)}
                                        />
                                    </div>

                                    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                        <p className="mt-2">Grand</p> &nbsp; &nbsp; &nbsp;
                                        <Form.Control type="text" className="mb-1" value={getGrandTotal()} disabled
                                        //   onChange={(e) => setM_lname(e.target.value)}
                                        />
                                    </div>

                                    <ButtonGroup aria-label="Basic example" style={{ float: "right" }}>
                                        <Button style={{ backgroundColor: "#22937c" }} size="sm" onClick={handleSubmit}>Place Order</Button> &nbsp;
                                        {/* <Button style={{ backgroundColor: "#2dc9cd" }} size="sm">Recurring</Button> */}
                                    </ButtonGroup>
                                </div>

                            </div>


                        </div>
                    </div>
                    <Rightnav />
                </div>
            </Form>
            <ToastContainer />

        </div >
    )
}

export default Neworder
