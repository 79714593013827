import React, { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal'
import DatePicker from "react-datepicker";
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import "react-datepicker/dist/react-datepicker.css";
import Rightnav from './Rightnav';
import Header from "./Header";
import { FaCalendar } from 'react-icons/fa';
import { useHistory } from 'react-router';
export default function Dashboard() {

   const history = useHistory();

   const [orderList, setOrderList] = useState([]);
   const [pendingConfOrders, setPendingConfOrders] = useState([]);
   const [customerList, setCustomerList] = useState([]);
   const [modalShow, setModalShow] = useState(false);
   const [designerModalShow, setDesignerModalShow] = useState(false);
   const [startDate, setStartDate] = useState(new Date());

   var month = startDate.getUTCMonth() + 1;
   var day = startDate.getUTCDate();
   var year = startDate.getUTCFullYear();

   const newStartDate = `${year}-${month}-${day}`;
   const [isOrderChecked, setIsOrderChecked] = useState(false);
   const [checkedOrderId, setCheckedOrderId] = useState();
   const [designer, setDesigner] = useState();
   const [toggle, setToggle] = useState(false);
   const [designRecipe, setDesignRecipe] = useState();


   // order Detail Data
   const [Od_dispatch, setOd_dispatch] = useState([]);
   const [Od_order, setOd_order] = useState([]);
   const [Od_items, setOd_items] = useState([]);
   console.log("Order Items:", Od_items);
   console.log("Order Dispatch:",Od_dispatch);
   console.log("orders details:",Od_order);

   function getSubTotal() {
      const sub = Od_items?.reduce((sub, { price, qty }) => sub + parseFloat(price) * parseFloat(qty), 0);
      return sub;
  }

  function getTotalDiscount() {
   const sub = Od_items?.reduce((sub, { discount_amt }) => sub + parseFloat(discount_amt), 0);
   return sub;
}

   // console.log("Order items:",Od_items);

   // console.log("Customer List:", customerList);
   const [pendingCount, setPendingCount] = useState(0);
   const [pendingConfCount, setPendingConfCount] = useState(0);
   const [waitCount, setWaitCount] = useState(0);
   const [nonDelvCount, setNonDelvCount] = useState(0);
   const [pendingPickCount, setPendingPickCount] = useState(0);

   useEffect(() => {
      getOrderStatusCount();
      getOrderList();
      getCustomerList();
      getPendingConfOrders();
   }, [newStartDate]);

   const getOrderStatusCount = () => {
      const loginObject = JSON.parse(localStorage.getItem('login'));
      let token = loginObject.jwt;
      let shopId = loginObject.shop_id;
      const finalUrl = "https://apifloragen.gamingpandastudios.com/api/order_status_count";
      const requestBody = {
         jwt: token,
         shop_id: shopId,
      }

      try {
         fetch(finalUrl, {
            method: "POST",
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(requestBody),
         })
            .then((res) => res.json())
            .then((data) => {
               console.log("Status Count:", data)
               setPendingCount(data.pending);
               setPendingConfCount(data.pending_confirmation);
               setWaitCount(data.wait_queue);
               setNonDelvCount(data.non_deliveries);
               setPendingPickCount(data.pending_pickup);

            })
            .catch((e) => console.log(e));
      }
      catch (e) {
         console.log(e);
      }
   }

   const handleAssignDesignerShow = (e) => {
      e.preventDefault();
      // console.log("Is Order Checked: ", isOrderChecked);
      // console.log("Checked Order Id:", checkedOrderId);
      setDesignerModalShow(!designerModalShow);
   }

   const handleChange = (e) => {
      setIsOrderChecked(e.target.checked);
      setCheckedOrderId(e.target.id);
      // console.log("Checked Order Id is:", e.target.id);
   }

   const [checkedList, setCheckedList] = useState([]);

   const handleChangeAssignedOrders = (e, order) => {
      e.preventDefault();
      let list = [...checkedList];
      list.push(order);
      setCheckedList(list);

   }

   const handleOrderDispatch = (e) => {
      e.preventDefault();
      const finalUrl = "https://apifloragen.gamingpandastudios.com/api/order_dispatch";
      const requestBody = {
         jwt: token,
         orders: checkedList.map((order) => ({
            order_id: order.order_id,
            shop_id: shopId
         })),
      }
      // console.log("Request Body: ", requestBody);
      try {
         fetch(finalUrl, {
            method: "POST",
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(requestBody),
         })
            .then((res) => res.json())
            .then((data) => {
               console.log("Order Dispatch Data info:", data)
               // setDesignerModalShow(!designerModalShow);
              
            })
            .catch((e) => console.log(e));
      }
      catch (e) {
         console.log(e);
      }
      // checkedList.map((order) => {
      //    setPendingConfOrders(pendingConfOrders.filter(pd => pd.order_id !== order.order_id));
      // })
      history.push('/home');
   }

   // console.log("Checked List is:", checkedList);

   const handleDesignerData = (e) => {
      e.preventDefault();
      const finalUrl = "https://apifloragen.gamingpandastudios.com/api/assign_designer";
      const requestBody = {
         order_id: checkedOrderId,
         shop_id: shopId,
         DesignerID: designer,
         DesignerDt: "",
         Order_receipes_Details: designRecipe,
         jwt: token
      }
      // console.log("Designer data is:", requestBody);

      try {
         fetch(finalUrl, {
            method: "POST",
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(requestBody),
         })
            .then((res) => res.json())
            .then((data) => {
               // console.log("Assign Designer Data info:", data)
               setDesignerModalShow(!designerModalShow);
            })
            .catch((e) => console.log(e));
         history.push('/home');
      }
      catch (e) {
         console.log(e);
      }

   }
   // console.log("checked order id is: ", checkedOrderId);

   const handleDesignComplete = (e) => {
      e.preventDefault();
      const finalUrl = "https://apifloragen.gamingpandastudios.com/api/order_design_complete";
      const requestBody = {
         order_id: checkedOrderId,
         shop_id: shopId,
         jwt: token
      }
      console.log("Request Body is:", requestBody);

      try {
         fetch(finalUrl, {
            method: "POST",
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(requestBody),
         })
            .then((res) => res.json())
            .then((data) => {
               console.log(" Design Complete info:", data)
               // setDesignerModalShow(!designerModalShow);
            })
            .catch((e) => console.log(e));
         history.push('/home');
      }
      catch (e) {
         console.log(e);
      }
   }

   const handleModalShow = (e, order_id) => {
      e.preventDefault();
      // console.log("order_id is:", order_id);
      const loginObject = JSON.parse(localStorage.getItem('login'));
      let token = loginObject.jwt;
      let shopId = loginObject.shop_id;
      const finalUrl = "https://apifloragen.gamingpandastudios.com/api/order_detail";
      const requestBody = {
         jwt: token,
         order_id: order_id,
         shop_id: shopId
      }

      try {
         fetch(finalUrl, {
            method: "POST",
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(requestBody),
         })
            .then((res) => res.json())
            .then((data) => {
               console.log("Order Detail Info: ", data);
               // setOrderList(data.records);
               setOd_dispatch(data.dispatch[0]);
               setOd_order(data.order[0]);
               setOd_items(data.items);
            })
            .catch((e) => console.log(e));
      }
      catch (e) {
         console.log(e);
      }

      setModalShow(!modalShow);
      // console.log("Customer Detail is:", e);
   }

   const getPendingConfOrders = () => {
      const finalUrl = "https://apifloragen.gamingpandastudios.com/api/order_list";
      const requestBody = {
         status: "pending_confirmation",
         shop_id: shopId,
         jwt: token
      }

      try {
         fetch(finalUrl, {
            method: "POST",
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(requestBody),
         })
            .then((res) => res.json())
            .then((data) => {
               console.log("Pending Confirmation Order Info: ", data.records);
               setPendingConfOrders(data.records);
            })
            .catch((e) => console.log(e));
      }
      catch (e) {
         console.log(e);
      }

   }

   const getOrderList = () => {
      const loginObject = JSON.parse(localStorage.getItem('login'));
      let token = loginObject.jwt;
      const finalUrl = "https://apifloragen.gamingpandastudios.com/api/order_date_search";
      const requestBody = {
         jwt: token,
         delivery_status: 'pending',
         order_date: newStartDate,
         shop_id: shopId
      }

      try {
         fetch(finalUrl, {
            method: "POST",
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(requestBody),
         })
            .then((res) => res.json())
            .then((data) => {
               console.log("Order Info: ", data.records);
               setOrderList(data.records);
            })
            .catch((e) => console.log(e));
      }
      catch (e) {
         console.log(e);
      }
   }

   // console.log("The Orders in Pending Deliveries are:",orderList);

   const loginObject = JSON.parse(localStorage.getItem('login'));
   let token = loginObject.jwt;
   let shopId = loginObject.shop_id;
   let userId = loginObject.user_id;
   let shopName = loginObject.shopname;

   const getCustomerList = () => {
      // console.log("Jwt:", token);
      // console.log("Shop Id is:", shopId);
      const finalUrl = "https://apifloragen.gamingpandastudios.com/api/customer_list";
      const requestBody = {
         shopid: shopId,
         jwt: token
      }

      try {
         fetch(finalUrl, {
            method: "POST",
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(requestBody),
         })
            .then((res) => res.json())
            .then((data) => {
               // console.log("Customer List Info: ", data);
               setCustomerList(data.records);
            })
            .catch((e) => console.log(e));
      }
      catch (e) {
         console.log(e);
      }
   }



   return (
      <>
         <Header />
         <Modal
            show={modalShow}
            onHide={() => setModalShow(false)}
            size="xl" aria-labelledby="example-modal-sizes-title-lg">
            <Modal.Header closeButton>
               <Modal.Title id="example-modal-sizes-title-lg">

               </Modal.Title>
            </Modal.Header>
            <Modal.Body>
               <div className="p-2 my-2">
                  <div className="row">
                     <div className="col-md-2">
                        <span className="blue">New</span>
                     </div>
                     <div className="col-md-6">
                        <h4 className="blue">#{Od_dispatch.invoice} - ${Od_order.totalamount} <small className="grey">({Od_order.dlv_type} {Od_order.ordertype})</small></h4>
                     </div>
                  </div>

                  <div className="row">
                     <div className="col-md-2">
                        <p className="blue">Sender</p>
                     </div>
                     <div className="col-md-6">
                        <small>{Od_order.firstname} {Od_order.lastname} 876-456-5677 {Od_order.address1 ? Od_order.address1 : Od_order.address2} {Od_order.city}, {Od_order.state}, {Od_order.zipcode}</small>
                     </div>
                  </div>

                  <div className="row">
                     <div className="col-md-2">
                        <p className="blue">Recipient</p>
                     </div>
                     <div className="col-md-6">
                        <small>{Od_dispatch.RECIPIENT_FIRST_NAME} {Od_dispatch.RECIPIENT_LAST_NAME} ({Od_dispatch.phone1}, {Od_dispatch.phone2}) {Od_dispatch.apt_floor}, {Od_dispatch.Address1 ? Od_dispatch.Address1 : Od_dispatch.Address2}, {Od_dispatch.City} {Od_dispatch.State}, {Od_dispatch.Country}, {Od_dispatch.ZIPCode}</small>
                     </div>
                  </div>
               </div>

               <div className="p-2 my-2" style={{ backgroundColor: "#faf1e9" }}>
                  <div className="row" >
                     <div className="col-md-3" >
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                           <p>Sold By</p>
                           <small className="grey">{shopName}</small>
                        </div>

                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                           <p>Occasion</p>
                           <small className="grey">{Od_order.occasion}</small>
                        </div>

                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                           <p>Location</p>
                           <small className="grey">{Od_dispatch.location}</small>
                        </div>

                     </div>

                     <div className="col-md-3" >
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                           <p>Placed On</p>
                           <small className="grey">{Od_order.order_date}</small>
                        </div>

                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                           <p>Delivery</p>
                           <small className="grey">{Od_order.dlvdate} {Od_order.dlvtime}</small>
                        </div>

                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                           <p>Register</p>
                           <small className="grey">Cash Register1</small>
                        </div>
                     </div>

                     <div className="col-md-3" >
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                           <p>Driver</p>
                           <small className="grey">-Unknown Unknown</small>
                        </div>

                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                           <p>Delivered</p>
                           {/* <p>11/24/2021</p> */}
                        </div>
                     </div>

                  </div>
               </div>

               <div className="p-2 my-2" style={{ backgroundColor: "#deeccc" }}>
                  <div className="row">
                     <div className="col-md-4">
                        <p>Card&nbsp;Message </p>
                     </div>
                     <div className="col-md-8">
                        <span> {Od_order.card_msg}</span>
                     </div>
                  </div>

                  <div className="row">
                     <div className="col-md-4">
                        <p>Comments </p>
                     </div>
                     <div className="col-md-8">
                        <span> {Od_order.customer_insts}</span>
                     </div>
                  </div>

                  <div className="row">
                     <div className="col-md-4">
                        <p>Special Message  </p>
                     </div>
                     <div className="col-md-8">
                        <span>{Od_order.special_insts} </span>
                     </div>
                  </div>
               </div>

               <div className="row">
                  <div className="col-md-12 main-content">
                     <ul className="nav nav-tabs" id="gamesTab" role="tablist">
                        <li className="nav-item">
                           <a className="nav-link active" id="products-tab" data-toggle="tab" href="#products" role="tab" aria-controls="products" aria-selected="true">
                              Products</a>
                        </li>
                        <li className="nav-item">
                           <a className="nav-link" id="dispatch-tab" data-toggle="tab" href="#dispatch" role="tab" aria-controls="dispatch" aria-selected="false">
                              Dispatch History</a>
                        </li>
                        <li className="nav-item">
                           <a className="nav-link" id="statusHist-tab" data-toggle="tab" href="#statusHist" role="tab" aria-controls="statusHist" aria-selected="false">
                              Status History</a>
                        </li>
                        <li className="nav-item">
                           <a className="nav-link" id="notes-tab" data-toggle="tab" href="#notes" role="tab" aria-controls="notes" aria-selected="false">
                              Notes</a>
                        </li>
                        <li className="nav-item">
                           <a className="nav-link" id="payments-tab" data-toggle="tab" href="#payments" role="tab" aria-controls="payments" aria-selected="false">
                              Payments</a>
                        </li>

                     </ul>
                     <hr style={{ marginTop: 1 }} />
                     <div className="tab-content" id="dashboardTabContent">
                        <div className="tab-pane fade show active" id="products" role="tabpanel" aria-labelledby="products-tab">
                           <div className="tab-content-div pt-2" style={{ backgroundColor: "#bcd2e8" }}>

                              <br />
                              <div className="table-responsive-sm px-2" style={{ backgroundColor: "white" }}>
                                 <table className="table">
                                    <thead>
                                       <tr>
                                          <th scope="col">Units</th>
                                          <th scope="col">Image</th>
                                          <th scope="col">Description</th>
                                          <th scope="col">Price</th>
                                          <th scope="col">Discount</th>
                                          <th scope="col">Discount %</th>
                                       </tr>
                                    </thead>
                                    <tbody>
                                       {
                                          Od_items ?
                                             Od_items && Od_items.map((od_item) => {
                                                return (
                                                   <tr>
                                                      <td>{od_item.qty}</td>
                                                      <td></td>
                                                      <td>Falls Feels</td>
                                                      <td className="blue">${od_item.price}</td>
                                                      <td className="blue">${od_item.discount_amt}</td>
                                                      <td className="blue">{od_item.discount_percentage}%</td>
                                                   </tr>
                                                )
                                             })
                                             :
                                             ""
                                       }


                                    </tbody>
                                 </table>
                              </div>
                              <div className="row offset-4" >
                                 <div className="col-md-6">
                                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                                       <p>Balanced Amount</p>
                                       <small className="grey">$ {getSubTotal()}</small>
                                    </div>

                                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                                       <p>Paid Amount</p>
                                       <small className="grey">$ {getSubTotal() - getTotalDiscount()}</small>
                                    </div>

                                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                                       <p>Payment Type</p>
                                       <small className="grey">Paid Outside Hana</small>
                                    </div>

                                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                                       <p>Payment reference</p>
                                       <small className="grey">paid</small>
                                    </div>
                                 </div>
                                 <div className="col-md-4">
                                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                                       <p>Relay</p>
                                       <small className="grey">$ 0.00</small>
                                    </div>

                                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                                       <p>Delivery Fee</p>
                                       <small className="grey">$ 0.00</small>
                                    </div>

                                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                                       <p>Discount Amount</p>
                                       <small className="red">($ {getTotalDiscount()})</small>
                                    </div>

                                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                                       <p>Sales Tax</p>
                                       <small className="grey">$ 8.50</small>
                                    </div>
                                 </div>
                              </div>
                              <div className=" row offset-6 py-4">
                                 <div className="col-md-8">
                                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                                       <p>GRAND TOTAL</p>
                                       <strong>$ {Od_order.totalamount}</strong>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                        <div className="tab-pane fade" id="dispatch" role="tabpanel" aria-labelledby="dispatch-tab">
                           <p>Dispatch History Here</p>
                        </div>
                        <div className="tab-pane fade" id="statusHist" role="tabpanel" aria-labelledby="statusHist-tab">
                           <p>Status History Here</p>
                        </div>
                        <div className="tab-pane fade" id="notes" role="tabpanel" aria-labelledby="notes-tab">
                           <p>Notes Here</p>
                        </div>
                        <div className="tab-pane fade" id="payments" role="tabpanel" aria-labelledby="payments-tab">
                           <p>Payments Here</p>
                        </div>

                     </div>
                  </div>
               </div>

            </Modal.Body>
         </Modal>

         <Modal
            show={designerModalShow}
            onHide={() => setDesignerModalShow(false)}
            size="xl" aria-labelledby="example-modal-sizes-title-lg">
            <Modal.Header closeButton>
               <Modal.Title id="example-modal-sizes-title-lg">
                  Invoice #: 106128721 - <b>Fulfillment Details</b>
               </Modal.Title>
            </Modal.Header>
            <Modal.Body>
               <Form>
                  <div className="row">
                     <div className="col-md-6">
                        <Form.Group className="mb-3">
                           <Form.Label>Designer</Form.Label>

                           <Form.Select aria-label="Default select example" className="form-control" value={designer}
                              onChange={(e) => setDesigner(e.target.value)}
                           >
                              <option>--Select User--</option>
                              <option value="1">User1</option>
                              <option value="2">User2</option>
                              <option value="3">User3</option>
                              <option value="4">User4</option>
                              <option value="5">User5</option>
                           </Form.Select>
                        </Form.Group>
                     </div>
                     <div className="col-md-6">
                        <div className="btn-group">
                           <p className="mt-1">Flower Design Completed</p> &nbsp;&nbsp;&nbsp;
                           <div className="custom-control custom-switch">
                              <input type="checkbox" className="custom-control-input" value={toggle} onClick={() => setToggle(!toggle)} id="customSwitches" />
                              <label className="custom-control-label" for="customSwitches"></label>
                           </div>
                        </div>
                     </div>


                  </div>
                  <div className="row">
                     <div className="col-md-12">
                        <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                           <Form.Label>Design Recipe</Form.Label>
                           <Form.Control as="textarea" rows={3} value={designRecipe} onChange={(e) => setDesignRecipe(e.target.value)} />
                        </Form.Group>
                     </div>
                  </div>

                  <div className="row">
                     <div className="col-md-2 ">
                        <Button variant="info" type="submit"
                           onClick={handleDesignerData}
                        >
                           Submit
                        </Button>
                     </div>
                  </div>
               </Form>

            </Modal.Body>
         </Modal>
         <section>
            <div className="row">
               <div className="col-md-10 left-section-outer">
                  <div className="left-section">
                     <div className="row">
                        <div className="col-md-6">
                           <h1 className="welcome-h1 animate__animated animate__fadeIn">Welcome, <span>{shopName}</span></h1>
                        </div>
                        <div className="col-md-6">
                           <div className="row">
                              <div className="col-md-4 select-divs animate__animated animate__fadeIn">
                                 <img src="assets/images/icons/online.png" className="top-select-img" alt="" />
                                 <select className="form-control top-select">
                                    <option value={shopId} key={shopId}>{shopName}</option>
                                 </select>
                              </div>
                              <div className="col-md-4 pl-2 select-divs animate__animated animate__fadeIn" style={{ zIndex: 100, display: "flex", alignItems: "center" }}>
                                 <FaCalendar /> &nbsp;
                                 <DatePicker className="form-control" selected={startDate} onChange={(date) => {
                                    setStartDate(date)
                                 }} />
                              </div>
                              {/* <div className="col-md-4 select-divs animate__animated animate__fadeIn">
                                 <select className="form-control top-select">
                                    <option>Advanced View</option>
                                 </select>
                              </div> */}
                           </div>
                        </div>
                     </div>
                     <br />
                     <div className="row">
                        <div className="col-md-2 inner-left-section">
                           <a href="">
                              <div className="side-selection animate__animated animate__slideInUp">
                                 <p className="active_p">Pending<br />Deliveries</p>
                                 <h2 className="active_h2">{pendingCount}</h2>
                                 <img src="assets/images/active-div.png" alt="" />
                              </div>
                           </a>
                           <a href="">
                              <div className="side-selection animate__animated animate__slideInUp">
                                 <p>Pending<br />Confirmations</p>
                                 <h2>{pendingConfCount}</h2>
                                 <img src="assets/images/div.png" alt="" />
                              </div>
                           </a>
                           {/* <a href="">
                              <div className="side-selection animate__animated animate__slideInUp">
                                 <p>Unread<br />Messages</p>
                                 <h2>0</h2>
                                 <img src="assets/images/div.png" />
                              </div>
                           </a> */}
                           <a href="">
                              <div className="side-selection animate__animated animate__slideInUp">
                                 <p>Wait<br />Queue</p>
                                 <h2>{waitCount}</h2>
                                 <img src="assets/images/div.png" alt="" />
                              </div>
                           </a>
                           <a href="">
                              <div className="side-selection animate__animated animate__slideInUp">
                                 <p>Non<br />Deliveries</p>
                                 <h2>{nonDelvCount}</h2>
                                 <img src="assets/images/div.png" alt="" />
                              </div>
                           </a>
                           <a href="">
                              <div className="side-selection animate__animated animate__slideInUp">
                                 <p>Pending<br />Pickup</p>
                                 <h2>{pendingPickCount}</h2>
                                 <img src="assets/images/div.png" alt="" />
                              </div>
                           </a>
                        </div>
                        <div className="col-md-10 inner-right-section">
                           <div className="row">
                              <div className="col-md-12 main-content animate__animated animate__slideInDown">
                                 <ul className="nav nav-tabs" id="gamesTab" role="tablist">
                                    <li className="nav-item">
                                       <a className="nav-link active" id="pending-tab" data-toggle="tab" href="#pending" role="tab" aria-controls="pending" aria-selected="true">
                                          Pending Deliveries</a>
                                    </li>
                                    {/* <li className="nav-item">
                                       <a className="nav-link" id="messages-tab" data-toggle="tab" href="#messages" role="tab" aria-controls="messages" aria-selected="false">
                                          Messages</a>
                                    </li> */}
                                    <li className="nav-item">
                                       <a className="nav-link" id="customer-tab" data-toggle="tab" href="#customer" role="tab" aria-controls="customer" aria-selected="false">
                                          Customers</a>
                                    </li>
                                    {/* <li className="nav-item">
                                       <a className="nav-link" id="ccreview-tab" data-toggle="tab" href="#ccreview" role="tab" aria-controls="ccreview" aria-selected="false">
                                          CC Review</a>
                                    </li> */}
                                    {/* <li className="nav-item">
                                       <a className="nav-link" id="paymentsreview-tab" data-toggle="tab" href="#paymentsreview" role="tab" aria-controls="paymentsreview" aria-selected="false">
                                          Payments Review</a>
                                    </li> */}
                                    <li className="nav-item">
                                       <a className="nav-link" id="designerreview-tab" data-toggle="tab" href="#designerreview" role="tab" aria-controls="designerreview" aria-selected="false">
                                          Designer Review</a>
                                    </li>
                                    <li className="nav-item">
                                       <a className="nav-link" id="driverAssignedOrders-tab" data-toggle="tab" href="#driverAssignedOrders" role="tab" aria-controls="driverAssignedOrders" aria-selected="false">
                                          Driver Assigned Orders</a>
                                    </li>
                                 </ul>
                                 <hr style={{ marginTop: 1 }} />
                                 <div className="tab-content" id="dashboardTabContent">
                                    <div className="tab-pane fade show active" id="pending" role="tabpanel" aria-labelledby="pending-tab">
                                       <div className="tab-content-div pt-2">
                                          <div className="row">
                                             <div className="col-md-6" >
                                                <div className="btn-group" style={{ width: '100%' }}>
                                                   <button className="btn btn-primary option-btn" onClick={handleAssignDesignerShow}>Assign Designer</button>
                                                   <button className="btn btn-primary option-btn">Confirm Delivery</button>
                                                   {/* <button className="btn btn-primary option-btn">Dispatch</button> */}
                                                </div>
                                             </div>
                                             <div className="col-md-6">
                                                {/* <div className="btn-group" style={{ width: '40%' }}>
                                                   <img src="assets/images/icons/search.png" className="search-icon-2" />
                                                   <p className="search_p">Search</p>
                                                </div> */}
                                                <div className="btn-group" style={{ width: '60%', float: 'right' }}>
                                                   <p className="sort_p">Sort By</p>
                                                   <select className="form-control order-select" >
                                                      <option>Order Date</option>
                                                   </select>
                                                   {/* <button className="btn btn-primary sort-btn"><img src="assets/images/icons/sort.png" className="sort-img" /></button> */}
                                                </div>
                                             </div>
                                          </div>
                                          <br />
                                          <div className="table-responsive-sm">
                                             <table className="table">
                                                <thead>
                                                   <tr>
                                                      <th scope="col"><input type="checkbox" name="" /></th>
                                                      <th scope="col">Action</th>
                                                      <th scope="col">Invoice Number</th>
                                                      <th scope="col">Order Details</th>
                                                      <th scope="col">Recipient</th>
                                                      <th scope="col">Sender/Customer</th>
                                                      <th scope="col">Date</th>
                                                   </tr>
                                                </thead>
                                                <tbody>
                                                   {
                                                      orderList ?
                                                         orderList.map((order, i) => {
                                                            return (
                                                               <tr>
                                                                  <th scope="row" key={order.order_id}>
                                                                     <input type="checkbox" id={order.order_id} checked={isOrderChecked[i]} onChange={(e) => handleChange(e)} />
                                                                  </th>
                                                                  <td></td>
                                                                  <td>
                                                                     <p className="blue" onClick={(e) => handleModalShow(e, order.order_id)}>{order.invoice}<span className="green" >New</span></p>
                                                                  </td>
                                                                  <td>
                                                                     <p>Order # : <span className="grey">{order.order_id}</span></p>
                                                                     <p>Type : <span className="grey">{order.ordertype}</span></p>
                                                                     <p>Delivery Type : <span className="grey">{order.dlv_type}</span></p>
                                                                     <p>MOP : <span className="grey">{order.mop}</span></p>
                                                                     <p>Total : <span className="blue">${order.totalamount}</span></p>
                                                                  </td>
                                                                  <td>
                                                                     <p>{order.recepient_firstname} {order.recepient_lastname}</p>
                                                                     <p className="grey">{order.recepient_address2}</p>
                                                                     <p className="grey">{order.recepient_city} {order.recepient_state} {order.recepient_zip}, {order.recepient_country}</p>
                                                                     <br />
                                                                     <p className="blue">{order.recepient_phone1}, {order.recepient_phone2}</p>
                                                                  </td>
                                                                  <td>
                                                                     <p>{order.firstname} {order.lastname}</p>
                                                                     <p className="grey">{order.address1} / {order.address2}</p>
                                                                     <p className="grey">{order.city} {order.state} {order.zipcode}</p>

                                                                     <br />
                                                                     <p className="blue">546 - 523 - 6374</p>
                                                                  </td>
                                                                  <td>
                                                                     <p>Delivery : <span className="grey">{order.dlvdate}</span></p>
                                                                     <p>Order : <span className="grey">
                                                                        {
                                                                           order.order_entry_datetime.split(" ")[0]
                                                                        }</span></p>
                                                                     <p>Time : <span className="grey">{order.dlvtime}</span></p>
                                                                  </td>
                                                               </tr>
                                                            )
                                                         })
                                                         :
                                                         <p>No Orders for this date</p>
                                                   }

                                                </tbody>
                                             </table>
                                          </div>
                                       </div>
                                    </div>
                                    <div className="tab-pane fade" id="messages" role="tabpanel" aria-labelledby="messages-tab">
                                       {/* <p>Message Content Here</p> */}
                                    </div>
                                    <div className="tab-pane fade" id="customer" role="tabpanel" aria-labelledby="customer-tab">
                                       {/* <p>Customers Content Here</p> */}
                                       <div className="tab-content-div pt-2">
                                          <div className="row">
                                             <div className="col-md-9" >
                                                <div className="btn-group" style={{ width: '100%' }}>
                                                   <button className="btn btn-primary option-btn">New Customer</button>
                                                   <button className="btn btn-primary option-btn">Merge Customer</button>
                                                   <button className="btn btn-primary option-btn">Email Invoices</button>
                                                   <button className="btn btn-primary option-btn">Print Invoices</button>
                                                   <button className="btn btn-primary option-btn">Generate Statements</button>
                                                </div>
                                             </div>
                                             <div className="col-md-3">

                                                <div className="btn-group" style={{ width: '60%', float: 'right' }}>
                                                   <button className="btn btn-primary sort-btn"><img src="assets/images/icons/sort.png" className="sort-img" /></button>
                                                </div>
                                             </div>
                                          </div>
                                          <br />
                                          <div className="table-responsive-sm" style={{ whiteSpace: "nowrap", overflow: "auto" }}>
                                             <table className="table">
                                                <thead>
                                                   <tr>
                                                      <th scope="col"><input type="checkbox" name="" /></th>&nbsp;&nbsp;
                                                      <th scope="col" >Action</th>&nbsp;&nbsp;
                                                      <th scope="col">Customer Id</th>&nbsp;&nbsp;
                                                      <th scope="col">Customer Name</th>&nbsp;&nbsp;
                                                      <th scope="col">Company Name</th>&nbsp;&nbsp;
                                                      <th scope="col">Phone Number</th>&nbsp;&nbsp;
                                                      <th scope="col">Address</th>&nbsp;&nbsp;
                                                      <th scope="col">City State Zip/ Postal</th>&nbsp;&nbsp;
                                                      <th scope="col">Account Balance</th>&nbsp;&nbsp;
                                                      <th scope="col">Store Credit</th>&nbsp;&nbsp;
                                                      <th scope="col">0-30</th>&nbsp;&nbsp;
                                                      <th scope="col">31-60</th>&nbsp;&nbsp;
                                                      <th scope="col">61-90</th>&nbsp;&nbsp;
                                                      <th scope="col">90+</th>&nbsp;&nbsp;
                                                   </tr>
                                                </thead>
                                                <tbody>
                                                   {
                                                      customerList.map((customer) => {
                                                         return (
                                                            <tr>
                                                               <th scope="row" key={customer.cust_id}><input type="checkbox" name="" /></th>&nbsp;
                                                               <td></td>&nbsp;&nbsp;
                                                               <td>
                                                                  <p className="blue">{customer.cust_id}</p>
                                                               </td>&nbsp;&nbsp;
                                                               <td>
                                                                  <p>{customer.firstname} {customer.lastname}</p>
                                                               </td>&nbsp;&nbsp;
                                                               <td>
                                                                  <p>{customer.companyname}</p>
                                                               </td>&nbsp;&nbsp;
                                                               <td>
                                                                  <p>{customer.phone1}</p>

                                                                  <p className="grey">{customer.phone2}</p>
                                                               </td>&nbsp;&nbsp;
                                                               <td>
                                                                  <p> {customer.address2}</p>
                                                               </td>&nbsp;&nbsp;
                                                               <td>
                                                                  <center>
                                                                     <p>{customer.city} {customer.state}</p>
                                                                     <p className="grey">  {customer.zip}</p>
                                                                  </center>
                                                               </td>&nbsp;&nbsp;
                                                               <td>
                                                                  <p>0.00</p>
                                                               </td>&nbsp;&nbsp;
                                                               <td>
                                                                  <p>{customer.store_credit}</p>
                                                               </td>&nbsp;&nbsp;
                                                               <td>
                                                                  <p>0.00</p>
                                                               </td>&nbsp;&nbsp;
                                                               <td>
                                                                  <p>0.00</p>
                                                               </td>&nbsp;&nbsp;
                                                               <td>
                                                                  <p>0.00</p>
                                                               </td>&nbsp;&nbsp;
                                                               <td>
                                                                  <p>0.00</p>
                                                               </td>&nbsp;&nbsp;
                                                            </tr>
                                                         )
                                                      })
                                                   }

                                                </tbody>
                                             </table>
                                          </div>
                                       </div>
                                    </div>
                                    <div className="tab-pane fade" id="ccreview" role="tabpanel" aria-labelledby="ccreview-tab">
                                       {/* <p>CC Review Content Here</p> */}
                                    </div>
                                    <div className="tab-pane fade" id="paymentsreview" role="tabpanel" aria-labelledby="paymentsreview-tab">
                                       {/* <p>Payments Review Content Here</p> */}
                                    </div>
                                    <div className="tab-pane fade" id="designerreview" role="tabpanel" aria-labelledby="designerreview-tab">
                                       <div className="tab-content-div pt-2">
                                          <div className="row">
                                             <div className="col-md-6" >
                                                <div className="btn-group" >
                                                   <button className="btn btn-primary option-btn" onClick={handleDesignComplete}>Design Completed</button>
                                                   {/* <button className="btn btn-primary option-btn">Confirm Delivery</button> */}
                                                   {/* <button className="btn btn-primary option-btn">Dispatch</button> */}
                                                </div>
                                             </div>
                                             {/* <div className="col-md-6">
                                                <div className="btn-group" style={{ width: '40%' }}>
                                                   <img src="assets/images/icons/search.png" className="search-icon-2" />
                                                   <p className="search_p">Search</p>
                                                </div>
                                                <div className="btn-group" style={{ width: '60%', float: 'right' }}>
                                                   <p className="sort_p">Sort By</p>
                                                   <select className="form-control order-select" >
                                                      <option>Order Date</option>
                                                   </select>
                                                   <button className="btn btn-primary sort-btn"><img src="assets/images/icons/sort.png" className="sort-img" /></button>
                                                </div>
                                             </div> */}
                                          </div>
                                          <br />
                                          <div className="table-responsive-sm">
                                             <table className="table">
                                                <thead>
                                                   <tr>
                                                      <th scope="col"><input type="checkbox" name="" /></th>
                                                      <th scope="col">Action</th>
                                                      <th scope="col">Invoice Number</th>
                                                      <th scope="col">Order Details</th>
                                                      <th scope="col">Recipient</th>
                                                      <th scope="col">Sender/Customer</th>
                                                      <th scope="col">Date</th>
                                                   </tr>
                                                </thead>
                                                <tbody>
                                                   {
                                                      pendingConfOrders ?
                                                         pendingConfOrders.map((order, i) => {
                                                            return (
                                                               <tr>
                                                                  <th scope="row" key={order.order_id}>
                                                                     <input type="checkbox" id={order.order_id} checked={isOrderChecked[i]} onChange={(e) => handleChange(e)} />
                                                                  </th>
                                                                  <td></td>
                                                                  <td>
                                                                     <p className="blue">{order.invoice}<span className="green" >New</span></p>
                                                                  </td>
                                                                  <td>
                                                                     <p>Order # : <span className="grey">{order.order_id}</span></p>
                                                                     <p>Type : <span className="grey">{order.ordertype}</span></p>
                                                                     <p>Delivery Type : <span className="grey">{order.dlv_type}</span></p>
                                                                     <p>MOP : <span className="grey">{order.mop}</span></p>
                                                                     <p>Total : <span className="blue">${order.totalamount}</span></p>
                                                                  </td>
                                                                  <td>
                                                                     <p>{order.recepient_firstname} {order.recepient_lastname}</p>
                                                                     <p className="grey">{order.recepient_address2}</p>
                                                                     <p className="grey">{order.recepient_city} {order.recepient_state} {order.recepient_zip}, {order.recepient_country}</p>
                                                                     <br />
                                                                     <p className="blue">{order.recepient_phone1}, {order.recepient_phone2}</p>
                                                                  </td>
                                                                  <td>
                                                                     <p>{order.firstname} {order.lastname}</p>
                                                                     <p className="grey">{order.address1} / {order.address2}</p>
                                                                     <p className="grey">{order.city} {order.state} {order.zipcode}</p>

                                                                     <br />
                                                                     <p className="blue">546 - 523 - 6374</p>
                                                                  </td>
                                                                  <td>
                                                                     <p>Delivery : <span className="grey">{order.dlvdate}</span></p>
                                                                     <p>Order : <span className="grey">
                                                                        {
                                                                           order.order_entry_datetime.split(" ")[0]
                                                                        }</span></p>
                                                                     <p>Time : <span className="grey">{order.dlvtime}</span></p>
                                                                  </td>
                                                               </tr>
                                                            )
                                                         })
                                                         :
                                                         <p>No Orders for this date</p>
                                                   }

                                                </tbody>
                                             </table>
                                          </div>
                                       </div>
                                    </div>

                                    <div className="tab-pane fade" id="driverAssignedOrders" role="tabpanel" aria-labelledby="driverAssignedOrders-tab">
                                       <div className="tab-content-div pt-2">
                                          <div className="row">
                                             <div className="col-md-6" >
                                                <div className="btn-group" >
                                                   {/* <button className="btn btn-primary option-btn" onClick={handleDesignComplete}>Design Completed</button> */}
                                                   {/* <button className="btn btn-primary option-btn">Confirm Delivery</button> */}
                                                   <button className="btn btn-primary option-btn" onClick={(e) => handleOrderDispatch(e)}>Dispatch</button>
                                                </div>
                                             </div>
                                             {/* <div className="col-md-6">
                                                <div className="btn-group" style={{ width: '40%' }}>
                                                   <img src="assets/images/icons/search.png" className="search-icon-2" />
                                                   <p className="search_p">Search</p>
                                                </div>
                                                <div className="btn-group" style={{ width: '60%', float: 'right' }}>
                                                   <p className="sort_p">Sort By</p>
                                                   <select className="form-control order-select" >
                                                      <option>Order Date</option>
                                                   </select>
                                                   <button className="btn btn-primary sort-btn"><img src="assets/images/icons/sort.png" className="sort-img" /></button>
                                                </div>
                                             </div> */}
                                          </div>
                                          <br />
                                          <div className="table-responsive-sm">
                                             <table className="table">
                                                <thead>
                                                   <tr>
                                                      <th scope="col"><input type="checkbox" name="" /></th>
                                                      <th scope="col">Action</th>
                                                      <th scope="col">Invoice Number</th>
                                                      <th scope="col">Order Details</th>
                                                      <th scope="col">Recipient</th>
                                                      <th scope="col">Sender/Customer</th>
                                                      <th scope="col">Date</th>
                                                   </tr>
                                                </thead>
                                                <tbody>
                                                   {
                                                      pendingConfOrders ?
                                                         pendingConfOrders.map((order, i) => {
                                                            return (
                                                               <tr>
                                                                  <th scope="row" key={order.order_id}>
                                                                     <input type="checkbox" id={order.order_id} checked={isOrderChecked[i]} onChange={(e) => handleChangeAssignedOrders(e, order)} />
                                                                  </th>
                                                                  <td></td>
                                                                  <td>
                                                                     <p className="blue">{order.invoice}<span className="green" >New</span></p>
                                                                  </td>
                                                                  <td>
                                                                     <p>Order # : <span className="grey">{order.order_id}</span></p>
                                                                     <p>Type : <span className="grey">{order.ordertype}</span></p>
                                                                     <p>Delivery Type : <span className="grey">{order.dlv_type}</span></p>
                                                                     <p>MOP : <span className="grey">{order.mop}</span></p>
                                                                     <p>Total : <span className="blue">${order.totalamount}</span></p>
                                                                  </td>
                                                                  <td>
                                                                     <p>{order.recepient_firstname} {order.recepient_lastname}</p>
                                                                     <p className="grey">{order.recepient_address2}</p>
                                                                     <p className="grey">{order.recepient_city} {order.recepient_state} {order.recepient_zip}, {order.recepient_country}</p>
                                                                     <br />
                                                                     <p className="blue">{order.recepient_phone1}, {order.recepient_phone2}</p>
                                                                  </td>
                                                                  <td>
                                                                     <p>{order.firstname} {order.lastname}</p>
                                                                     <p className="grey">{order.address1} / {order.address2}</p>
                                                                     <p className="grey">{order.city} {order.state} {order.zipcode}</p>

                                                                     <br />
                                                                     <p className="blue">546 - 523 - 6374</p>
                                                                  </td>
                                                                  <td>
                                                                     <p>Delivery : <span className="grey">{order.dlvdate}</span></p>
                                                                     <p>Order : <span className="grey">
                                                                        {
                                                                           order.order_entry_datetime.split(" ")[0]
                                                                        }</span></p>
                                                                     <p>Time : <span className="grey">{order.dlvtime}</span></p>
                                                                  </td>
                                                               </tr>
                                                            )
                                                         })
                                                         :
                                                         <p>No Orders for this date</p>
                                                   }

                                                </tbody>
                                             </table>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
               <Rightnav />
            </div>
         </section></>
   )
}

