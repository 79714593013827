import React from 'react'
import { Link } from 'react-router-dom';
export default function Responsiverightnav() {
    return (
        <div className="col-md-1 right-section">
       <ul className="">
       <li className="active animate__animated animate__slideInUp">
            <Link to='/home' className="a_active"><img src="assets/images/icons/dashboard.png" className="right-section-icons" alt="dashboard-icon" /></Link></li>
            <li className="animate__animated animate__slideInUp">
            <Link to='/home' className="a_active"><img src="assets/images/icons/new-order.png" className="right-section-icons" alt="new-order-icon" /></Link></li>
            <li className="animate__animated animate__slideInUp">
            <Link to='/cash' className="a_active"><img src="assets/images/icons/cash-and-carry.png" className="right-section-icons" alt="cash-and-carry-icon" /></Link></li>
            <li className="animate__animated animate__slideInUp">
            <Link to='/dispatch' className="a_active"><img src="assets/images/icons/dispatch.png" className="right-section-icons" alt="dispatch-icon" /></Link></li>
            <li className="animate__animated animate__slideInUp">
            <Link to='/home' className="a_active"><img src="assets/images/icons/reports.png" className="right-section-icons" alt="reports-icon" /></Link></li>
            <li className="animate__animated animate__slideInUp">
            <Link to='/home' className="a_active"><img src="assets/images/icons/configuration.png" className="right-section-icons" alt="configuration-icon" /></Link></li>
            <li className="animate__animated animate__slideInUp">
            <Link to='/home' className="a_active"><img src="assets/images/icons/more.png" className="right-section-icons" alt="more-icon" /></Link></li>
          </ul>
        <hr/>
      
        {/* <div className="info-div animate__animated animate__zoomIn">
          <p>Version 1.0</p>
          <p style={{color: "#007bff", fontSize: 9}}>Developed by <br/>Eighty5Technologies</p>
        </div> */}
      
        </div>
    );
}
