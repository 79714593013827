import React, { useState } from 'react';
import axios from 'axios';
import { useHistory } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Loginscreen = () => {
  const history = useHistory();
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [login, setLogin] =useState(false);
    const [store, setStore] = useState(null);

    const handleLogin = (e) => {
      e.preventDefault();
      const data = {
        email:email,
        password: password
      }
      const url = "https://apifloragen.gamingpandastudios.com/api/login";
      fetch(url, {
        method: "POST",
        body: JSON.stringify(data)
      }).then((response) => {
        response.json().then((result) => {
          console.log("result", result);
          localStorage.setItem('login', JSON.stringify({
            login: true,
            jwt: result.jwt,
            shop_id: result.shop_id,
            shopname: result.shopname,
            user_id: result.user_id,
          }))
          console.log(JSON.parse(localStorage.getItem('login')));  // use json parse to fetch the data of localstorage
          setLogin(true);
          // localStorage.setItem('user_key', result.data.jwt);
          // console.log("the jwt token", result.jwt);
          if(result.message !== 'Successful login.') {
            history.push('/');
            toast.error('Login failed.', {
              position:'top-center',
            })
            setError(true);
            setErrorMessage('Login failed.');

          }
          else {
            history.push('/home');
          }
          
        })
      })
      .catch(err => {
        console.log(err);
      })
        
    }

  return (
      <main className="d-flex align-items-center min-vh-100 py-3 py-md-0">
        <div className="container">
          <div className="card login-card">
            <div className="row no-gutters">
              <div className="col-md-4">
                <img src="assets/images/login.jpg" alt="floraGen" className="login-card-img" />
              </div>
              <div className="col-md-8">

                <div className="card-body">
                  <div className="brand-wrapper">
                    <img src="assets/images/logo.png" alt="floraGen" className="logo" />
                    </div>
                    <div className="topSignin">
                    <p className="login-card-description">Sign in to your <span className="blueColor">Account</span></p>
                    <p className="topSigninSmall">Enter Your credentials to login</p>
                    <hr></hr>
                    </div>
                    <form action="home" method="get">
                      <div className="form-group">
                        <label for="email" className="sr-only-label">Email</label>
                        <input type="email" name="email" id="email" className="form-control" placeholder="Email address" value={email} onChange={(e) => setEmail(e.target.value)} required/>
                      </div>
                      <div className="form-group mb-4">
                        <div className="topLabel"><span><label for="password" className="sr-only-label">Password</label></span>
                        <span className="floatRight"><a href="#!" className="forgot-password-link">Forgot password?</a></span></div>
                        <input type="password" name="password" id="password" className="form-control" placeholder="***********" value={password} onChange={(e) => setPassword(e.target.value)} required/>
                      </div>
                      <input name="login" id="login" className="btn btn-block btn-grad mb-4" type="submit" value="Login" onClick={(e) => handleLogin(e)} />
                    </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ToastContainer />
      </main>
  )
}

export default Loginscreen;
